import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

interface StyledGreyInfoBoxProps {
  $big?: boolean;
}

export const StyledGreyInfoBox = styled.section<StyledGreyInfoBoxProps>`
  background-color: ${({ $big }) => $big && colors.grey1};
  margin: ${({ $big }) => ($big ? 0 : `${spacing.l} ${spacing.s}`)};
  width: ${({ $big }) => ($big ? '100%' : null)};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${({ $big }) => ($big ? 0 : `${spacing.xl} 0 ${spacing.m} 0`)};
  }

  .content {
    background-color: ${({ $big }) => !$big && colors.grey1};
    padding: ${spacing.l} ${spacing.side};

    & > div {
      *:last-child {
        margin-bottom: 0;
      }
    }

    h2 {
      margin-bottom: ${spacing.s};
    }
  }
`;

export const StyledMarkdown = styled(Markdown)`
  & > *:not(:last-child) {
    margin-bottom: ${spacing.s};
  }
`;
