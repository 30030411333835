import { createCategoryUrl } from '@hultafors/wibe/helpers';
import { GridCategoryFragment } from '@hultafors/wibe/types';

import { CategoryBox } from '../category-box/category-box';
import { Grid } from '../grid/grid';

interface CategoryGridProps {
  categories?: GridCategoryFragment[];
  desktopQuad?: boolean;
  parentSlug?: string;
}

export const CategoryGrid: React.FC<CategoryGridProps> = ({
  categories = [],
  desktopQuad,
  parentSlug,
}) => {
  if (!categories?.length) {
    return null;
  }

  const boxMapper = (category: GridCategoryFragment, index: number) => {
    return (
      <CategoryBox
        key={`category-box-${index}`}
        name={category.name || ''}
        url={createCategoryUrl({
          parentSlug: parentSlug ? parentSlug : category.slug || '',
          childSlug: !parentSlug ? undefined : category.slug || '',
        })}
        description={category.description || ''}
        image={category.image}
      />
    );
  };

  return (
    <Grid
      columns={[
        { columns: 1 },
        { breakpoint: 'mobileMax', columns: 2 },
        { breakpoint: 'desktop', columns: desktopQuad ? 4 : 3 },
      ]}
      columnGap={2}
      rowGap={2}
    >
      {categories.map(boxMapper)}
    </Grid>
  );
};
