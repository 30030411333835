import { useEffect, useId, useRef, useState } from 'react';

import { AnimatePresence, m } from 'framer-motion';
import { LinkProps } from 'next/link';
import { AccordionItemState } from 'react-accessible-accordion';

import {
  AccordionItemButtonStyled,
  AccordionItemContent,
  AccordionItemHeadingStyled,
  AccordionItemPanelStyled,
  AccordionItemStyled,
  AccordionLinkStyled,
  ButtonInner,
} from './accordion-item.styled';

// Hack due to React Accessible Accordion not exporting types
type DivAttributes = React.HTMLAttributes<HTMLDivElement>;
type ID = string | number;
declare type AccordionItemProps = DivAttributes & {
  uuid?: ID;
  activeClassName?: string;
  dangerouslySetExpanded?: boolean;
  label?: React.ReactNode;
  level?: number;
  menu?: boolean;
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  label = '',
  level = 1,
  menu,
  dangerouslySetExpanded,
  uuid,
  ...rest
}) => {
  const uid = useId();
  const [height, setHeight] = useState(0);

  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (panelRef.current) {
      setHeight(
        panelRef.current.scrollHeight
        + 72
        + (Array.from(panelRef.current.querySelectorAll('*'))
          .map((item) => item.scrollHeight)
          .sort()
          .shift() || 0),
      );
    }
  }, [panelRef]);

  function statefulContent({
    expanded,
  }: Partial<{ expanded: boolean; disabled: boolean }>) {
    return (
      <AnimatePresence>
        {expanded && (
          <m.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <AccordionItemContent ref={panelRef}>
              {children}
            </AccordionItemContent>
          </m.div>
        )}
      </AnimatePresence>
    );
  }

  return (
    <AccordionItemStyled
      {...rest}
      dangerouslySetExpanded={dangerouslySetExpanded}
      uuid={uuid ?? uid}
    >
      <AccordionItemHeadingStyled aria-level={level || rest['aria-level']}>
        <AccordionItemButtonStyled>
          <ButtonInner $menu={menu}>{label || ''}</ButtonInner>
        </AccordionItemButtonStyled>
      </AccordionItemHeadingStyled>
      <AccordionItemPanelStyled $height={height}>
        <AccordionItemState>{statefulContent}</AccordionItemState>
      </AccordionItemPanelStyled>
    </AccordionItemStyled>
  );
};

interface AccordionLinkProps extends LinkProps {
  children?: React.ReactNode;
  href: string;
  className?: string;
  menu?: boolean;
}

export const AccordionLink: React.FC<AccordionLinkProps> = ({
  children,
  href = '',
  onClick,
  className,
  menu,
}) => (
  <AccordionLinkStyled
    href={href}
    onClick={onClick}
    className={className}
    $menu={menu}
  >
    {children}
  </AccordionLinkStyled>
);
