import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { GridChild } from '../grid-child/grid-child';

import { getColorByIndex } from './color-plugs';

export const StyledColorPlugs = styled.section`
  margin: ${spacing.l} ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.l} ${spacing.side};
  }
`;

interface IndexProps {
  $index: number;
}

export const StyledGridChild = styled(GridChild)<IndexProps>`
  background-color: ${({ $index = 0 }) => {
    switch ($index % 3) {
      case 0:
        return colors.wibeDarkGrey;
      case 1:
        return colors.wibeDarkSteel;
      case 2:
        return colors.wibeLightSteel;
      default:
        return colors.wibeDarkGrey;
    }
  }};
  display: flex;
  flex-direction: column;
  min-height: 200px;
  padding: ${spacing.side} ${spacing.m};
  transition: 0.2s background-color;

  &:hover {
    background-color: ${({ $index = 0 }) => {
      switch ($index % 3) {
        case 0:
          return colors.wibeDarkGreyHover;
        case 1:
          return colors.wibeDarkSteelHover;
        case 2:
          return colors.wibeLightSteelHover;
        default:
          return colors.wibeDarkGreyHover;
      }
    }};
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    min-height: 280px;
  }
`;

export const Arrow = styled.div<IndexProps>`
  margin-top: ${spacing.xs};

  path {
    fill: ${({ $index }) => colors[getColorByIndex($index)]};
  }
`;
