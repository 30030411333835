import { CtaFragment } from '@hultafors/wibe/types';

import {
  Description,
  IntroSectionStyled,
  RoundedButton,
  SecondCta,
  StyledTextButton,
  Title,
} from './intro-section.styled';

interface IntroSectionProps {
  title: string;
  description: string;
  cta?: CtaFragment;
  textButtonStyle?: boolean;
  secondCta?: CtaFragment;
  ctaList?: CtaFragment[];
}

export const IntroSection: React.FC<IntroSectionProps> = ({
  title,
  description,
  ctaList,
  textButtonStyle,
  secondCta,
}) => {
  const ctaListMapper = ({ id, label, url }: CtaFragment) => {
    if (!url) {
      return null;
    }
    const key = `IntroSectionCtaList-${id}`;
    return textButtonStyle && !secondCta
      ? (
        <StyledTextButton key={key} to={url}>
          {label}
        </StyledTextButton>
        )
      : (
        <RoundedButton key={key} to={url}>
          {label}
        </RoundedButton>
        );
  };

  return (
    <IntroSectionStyled>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {ctaList?.map(ctaListMapper)}
      {secondCta?.url && (
        <SecondCta to={secondCta.url}>{secondCta.label}</SecondCta>
      )}
    </IntroSectionStyled>
  );
};
