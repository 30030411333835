import styled, { css } from 'styled-components';

import { colors, depth, spacing } from '@hultafors/wibe/helpers';

import {
  paragraph10Style,
  paragraph14MediumStyle,
  paragraph14Style,
} from '../paragraph/paragraph.styled';

export const ProductFeaturesList = styled.ul<{ $absolute?: boolean }>`
  margin: 0;
  z-index: 3;
  position: relative;
  padding: 0;

  ${({ $absolute }) =>
    $absolute
    && css`
      left: ${spacing.xs};
      position: absolute;
      top: ${spacing.xs};
      z-index: ${depth.elevated};
    `}
  li {
    display: inline-block;
    margin-right: ${spacing.xs};
  }
`;

type ProductFeatureVariant =
  | 'prof'
  | 'prof-plus'
  | 'base'
  | 'good-work-environment-choice';

interface ProductFeatureItemProps {
  $variant?: string;
}

function getVariant({ $variant }: ProductFeatureItemProps) {
  if ($variant === 'prof') {
    return css`
      background: ${colors.wibeYellow};
      ${paragraph14MediumStyle};
    `;
  }
  if ($variant === 'prof-plus') {
    return css`
      ${paragraph14MediumStyle};
      background: ${colors.wibeDarkGrey};
      color: ${colors.wibeYellow};
    `;
  }
  if ($variant === 'base') {
    return css`
      ${paragraph14MediumStyle};
      background: ${colors.white};
      color: ${colors.wibeDarkGrey};
      border: thin solid ${colors.wibeDarkGrey};
    `;
  }
  if ($variant === 'good-work-environment-choice') {
    return css`
      ${paragraph10Style};
      background: ${colors.wibeGreen};
      color: ${colors.white};
      font-weight: 600;
      letter-spacing: 1px;
      padding-inline: 0;
      padding-block: 0;

      svg {
        margin-right: 6px;
      }
    `;
  }
  return css``;
}

export const ProductFeatureItem = styled.li<ProductFeatureItemProps>`
  display: inline-flex;
  position: relative;
  list-style: none;
  text-transform: uppercase;
  border-radius: ${spacing.xxs};
  align-self: flex-start;
  padding-inline: ${spacing.xs};
  padding-block: ${spacing.xxs};
  ${getVariant};
`;

export const New = styled.li`
  list-style: none;
  ${paragraph14Style};
  background: ${colors.wibeNew};
  padding-inline: 10px;
  padding-block: ${spacing.xxs};
  color: ${colors.white};
  text-align: center;
  border-radius: 100px;
`;
