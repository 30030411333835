import { useId } from 'react';

import dynamic from 'next/dynamic';

import { ProductDetailsApiDocument } from '@hultafors/shared/types';

import { colors, spacing } from '@hultafors/wibe/helpers';
import { WibeProductDetails } from '@hultafors/wibe/types';

import { Paragraph } from '../paragraph/paragraph';

const Accordion = dynamic(() =>
  import('../accordion/accordion').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('../accordion-item/accordion-item').then((mod) => mod.AccordionItem),
);
const DownloadFileBox = dynamic(() =>
  import('../download-file-box/download-file-box').then(
    (mod) => mod.DownloadFileBox,
  ),
);

interface ProductInformationAccordionProps {
  product: WibeProductDetails;
  documentsLabel: string;
  detailsLabel: string;
  componentDocumentsLabel: string;
}

export const ProductInformationAccordion: React.FC<
  ProductInformationAccordionProps
> = ({ product, documentsLabel, detailsLabel, componentDocumentsLabel }) => {
  const uuid = useId();

  function documentMapper(document: ProductDetailsApiDocument, index: number) {
    return (
      <DownloadFileBox
        key={`document-${document.url}-${index}`}
        url={document.url}
        label={document.name}
        size={document.size}
        filetype={document.filetype}
      />
    );
  }

  const detailsUuid = `${uuid}-details`;

  // Todo: intro open new accordion
  return (
    <Accordion
      style={{ marginBottom: spacing.l }}
      preExpanded={[detailsUuid]}
      allowZeroExpanded={true}
    >
      {product.intro && (
        <AccordionItem label={detailsLabel} uuid={detailsUuid}>
          <Paragraph
            styleType="paragraph16"
            style={{ marginBottom: spacing.m, color: colors.systemBlack }}
          >
            {product.intro}
          </Paragraph>
        </AccordionItem>
      )}
      {product.documents.length > 0 && (
        <AccordionItem label={documentsLabel}>
          {product.documents.map(documentMapper)}
        </AccordionItem>
      )}
      {product.componentDocuments.length > 0 && (
        <AccordionItem label={componentDocumentsLabel}>
          {product.componentDocuments.map(documentMapper)}
        </AccordionItem>
      )}
    </Accordion>
  );
};
