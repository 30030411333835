import styled from 'styled-components';

import { colors, spacing } from '@hultafors/wibe/helpers';

export const ProductImageDialogStyled = styled.aside`
  .DialogImage {
    border: thin solid ${colors.grey2};
    margin-bottom: ${spacing.m};
    width: 100%;

    &.Show {
      display: block;
    }

    &.Hide {
      display: none;
    }

    img {
      display: block;

      /* width: 100%; */
      height: auto;
      margin: 0 auto;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .DialogImage:first-of-type {
    margin-top: ${spacing.m};
  }

  button {
    position: fixed;
  }
`;
