import dynamic from 'next/dynamic';
import { ID } from 'react-accessible-accordion/dist/types/components/ItemContext';

import { Store } from '@hultafors/shared/types';

import {
  FindRetailersListStyled,
  Label,
  SubLabel,
} from './find-retailers-list.styled';

const Accordion = dynamic(() =>
  import('../accordion/accordion').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('../accordion-item/accordion-item').then((mod) => mod.AccordionItem),
);
const StoreItem = dynamic(() =>
  import('../store-item/store-item').then((mod) => mod.StoreItem),
);

interface FindRetailersListProps {
  stores: Store[];
  selectItem?(id: string): void;
  selectedItem?: Store;
  viewOnMap?: string;
  thisStoreAlsoSellsLabel?: string;
  visitWebsiteLabel?: string;
}

export const FindRetailersList: React.FC<FindRetailersListProps> = ({
  stores = [],
  selectItem,
  selectedItem,
  viewOnMap,
  thisStoreAlsoSellsLabel,
  visitWebsiteLabel,
}) => {
  const storeMapper = (store: Store) => {
    if (!store?.data) {
      return null;
    }
    const key = `Store-${store.id}`;
    const { data } = store;
    const label = (
      <div>
        <Label>{data.companyName}</Label>
        <SubLabel>{data.city}</SubLabel>
      </div>
    );

    return (
      <AccordionItem
        uuid={store.id}
        key={key}
        label={label}
        dangerouslySetExpanded={selectedItem?.id === store.id || undefined}
      >
        <StoreItem
          coordinates={
            data.longitude && data.latitude
              ? { lng: data.longitude, lat: data.latitude }
              : undefined
          }
          address={data.address1}
          zipCode={data.zipCode}
          city={data.city}
          country={data.country}
          mapLink={store.mapLink}
          view_on_map={viewOnMap}
          emailAddress={data.emailAddress}
          phone={data.phone}
          storeSells={store.storeSells}
          this_store_also_sells={thisStoreAlsoSellsLabel}
          website={data.webSite}
          visit_web_site={visitWebsiteLabel}
          openingHours={data.openingHours}
        />
      </AccordionItem>
    );
  };

  function onChange(ids: ID[]) {
    const id = ids[0];
    if (id) {
      selectItem?.(`${id}`);
    }
  }
  if (!stores.length) {
    return null;
  }
  return (
    <FindRetailersListStyled>
      <Accordion allowZeroExpanded onChange={onChange}>
        {stores.map(storeMapper)}
      </Accordion>
    </FindRetailersListStyled>
  );
};
