import { useRef, useState } from 'react';

import Image from 'next/image';
import Slider from 'react-slick';

import { breakpoints } from '@hultafors/shared/constants';
import { ParsedImage } from '@hultafors/shared/types';

import { createProductImageSrc } from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';

import { Modal } from '../modal/modal';
import { ModalBottomSlider } from '../modal-bottom-slider/modal-bottom-slider';
import { Paragraph } from '../paragraph/paragraph';

import {
  ImgWrapper,
  ModalContent,
  ModalImageWrapper,
  ProductSliderStyled,
  SliderAndContentWrapper,
  SliderAndCounterWrapper,
  Thumbnail,
  ThumbnailWrapper,
  ZoomIcon,
} from './product-slider.styled';

interface ProductSliderProps {
  images: ParsedImage[];
  productId?: string;
}

export const ProductSlider: React.FC<ProductSliderProps> = ({
  images,
  productId,
}) => {
  const { global } = useGlobal();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const slider = useRef<any>(null);

  const changedIndex = (index: number) => {
    setImageIndex(index);
    setCurrentIndex(index);
  };

  const SETTINGS = {
    arrows: true,
    dots: false,
    adaptiveHeight: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    nextArrow: <Arrow className="Next" aria-label={global?.next || ''} />,
    prevArrow: <Arrow className="Prev" aria-label={global?.previous || ''} />,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          arrows: false,
        },
      },
    ],
    afterChange: changedIndex,
  };

  const canRenderImage = () => {
    return Array.isArray(images) && images.length > 0;
  };

  const formatColor = (color: string) => {
    return encodeURI(color.substring(1, color.length));
  };

  const handleOpenModal = (index: number) => {
    setImageIndex(index);
    setModalVisible(true);
  };

  const handleModalImageSlide = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      goToSlider(imageIndex !== 0 ? imageIndex - 1 : images.length - 1);
    }
    if (direction === 'next') {
      goToSlider(imageIndex !== images.length - 1 ? imageIndex + 1 : 0);
    }
  };

  const goToSlider = (i: number) => {
    changedIndex(i);
    // eslint-disable-next-line
    slider.current?.slickGoTo(i);
  };

  const sizes = [
    `(min-width: ${breakpoints.desktopLarge}) 474px`,
    `(min-width: ${breakpoints.desktop}) calc(50vw - 246px)`,
    `(min-width: ${breakpoints.desktopSmall}) calc(100vw - 270px)`,
    '100vw',
  ].join(', ');

  function thumbnailMapper(image: ParsedImage, index: number) {
    if (!image?.url) {
      return null;
    }
    return (
      <Thumbnail
        key={`productItem${index}`}
        $active={currentIndex === index}
        onClick={() => goToSlider(index)}
      >
        <Image
          src={createProductImageSrc(image.url)}
          alt={image.alt || ''}
          width={82}
          height={82}
        />
      </Thumbnail>
    );
  }

  function imageMapper(image: ParsedImage, index: number) {
    if (!image?.url) {
      return null;
    }

    return (
      <ImgWrapper
        key={`SliderImage${index}`}
        onClick={() => handleOpenModal(index)}
        aria-label={global?.showLargeImage || ''}
      >
        <span>
          <Image
            src={createProductImageSrc(image.url)}
            alt={image.alt}
            fill
            sizes={sizes}
            priority={index === 0}
          />
        </span>
      </ImgWrapper>
    );
  }

  const RenderImage: React.FC = () => {
    if (!canRenderImage()) {
      return <Image src="/assets/gfx/noimage.png" alt="" />;
    }
    return (
      <SliderAndContentWrapper>
        <ThumbnailWrapper>
          {images.length > 1 && images.map(thumbnailMapper)}
        </ThumbnailWrapper>
        <SliderAndCounterWrapper>
          <ZoomIcon />

          <Slider
            key={productId}
            {...SETTINGS}
            className="article"
            ref={slider}
          >
            {images.map(imageMapper)}
          </Slider>
          {images.length > 1 && (
            <div className="Counter">
              <Paragraph styleType="paragraph12">
                {currentIndex ? currentIndex + 1 : 1}
                {' '}
                /
                {images.length}
              </Paragraph>
            </div>
          )}
        </SliderAndCounterWrapper>
      </SliderAndContentWrapper>
    );
  };

  return (
    <ProductSliderStyled>
      {modalVisible && (
        <Modal isOpen={modalVisible} setModalVisible={setModalVisible}>
          <ModalContent>
            <ModalImageWrapper>
              <Image
                src={createProductImageSrc(images[imageIndex]?.url || '')}
                alt={images[imageIndex]?.alt || ''}
                sizes="100vw"
                fill
              />
            </ModalImageWrapper>
            <ModalBottomSlider
              index={imageIndex + 1}
              total={images.length}
              onClick={handleModalImageSlide}
            />
          </ModalContent>
        </Modal>
      )}
      <RenderImage />
    </ProductSliderStyled>
  );
};

interface ArrowProps {
  'className'?: string;
  'onClick'?: React.MouseEventHandler;
  'aria-label': string;
}

const Arrow: React.FC<ArrowProps> = ({ className, onClick, ...props }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      aria-label={props['aria-label']}
    />
  );
};
