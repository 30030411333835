import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { ImageBlockFragment, Maybe } from '@hultafors/wibe/types';

import { Button } from '../button/button';
import { TextButton } from '../text-button/text-button';

import {
  ButtonWrapper,
  Container,
  ImageContainer,
  StyledParagraph,
} from './dynamic-image.styled';

interface DynamicImageImageProps {
  image: ImageBlockFragment['image'];
  label?: Maybe<string>;
  ctaText?: Maybe<string>;
  ctaLink?: Maybe<string>;
  ctaIsLink?: boolean;
}

export const DynamicImage: React.FC<DynamicImageImageProps> = ({
  image,
  label,
  ctaText,
  ctaLink,
  ctaIsLink,
}) => {
  if (!image?.responsiveImage?.src) {
    return null;
  }

  const sizes = [
    `(min-width: ${breakpoints.desktop}) 50vw`,
    `(min-width: ${breakpoints.mobileMax}) 66vw`,
    'calc(100vw - 32px)',
  ].join(', ');

  return (
    <Container>
      <ImageContainer $aspectRatio={image.responsiveImage.aspectRatio}>
        <Image
          src={image.responsiveImage.src}
          sizes={sizes}
          alt={image.alt || ''}
          placeholder={image.responsiveImage.base64 ? 'blur' : 'empty'}
          blurDataURL={image.responsiveImage.base64 || ''}
          fill
        />
      </ImageContainer>

      {label && (
        <StyledParagraph styleType="paragraph16">{label}</StyledParagraph>
      )}
      {ctaText && ctaLink && (
        <ButtonWrapper>
          {ctaIsLink
            ? (
              <TextButton to={ctaLink} arrow>
                {ctaText}
              </TextButton>
              )
            : (
              <Button to={ctaLink} variant="rounded">
                {ctaText}
              </Button>
              )}
        </ButtonWrapper>
      )}
    </Container>
  );
};
