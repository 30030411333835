import styled from 'styled-components';

import { colors, spacing } from '@hultafors/wibe/helpers';

interface StyledLoaderProps {
  $fullscreen?: boolean;
  $small?: boolean;
}

export const StyledLoader = styled.div<StyledLoaderProps>`
  align-items: center;
  display: flex;
  height: ${({ $fullscreen }) => ($fullscreen ? '100vh' : 'auto')};
  justify-content: center;
  margin: ${spacing.m} 0;

  svg {
    fill: ${colors.grey2};
    height: 16px;
    width: 64px;
  }
`;
