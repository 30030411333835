import { useEffect, useState } from 'react';

import Range from 'rc-slider';

// import ReactSlider from 'react-slider'
import { MinMax } from '@hultafors/shared/types';

import { getFilterSummary } from '@hultafors/wibe/api';

import { Paragraph } from '../paragraph/paragraph';

import { FilterSliderStyled } from './filter-slider.styled';

interface FilterSliderProps {
  fromLabel?: string;
  toLabel?: string;
  filter: any;
  filterChange?(filter: any, result: any): void;
  selectedFilters?: any[];
  onChange?(value: number | number[]): void;
}

export const FilterSlider: React.FC<FilterSliderProps> = ({
  filter,
  fromLabel = 'From',
  toLabel = 'To',
  filterChange,
  selectedFilters = [],
  onChange,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const getSelectedSummary = (
    filter?: any,
    selectedFilters?: any[],
    filterSummary?: any,
  ) => {
    const parent = selectedFilters?.find((x) => x.id === filter.id);
    if (parent) {
      const summary = getFilterSummary(parent);

      return {
        ...filterSummary,
        min: summary.min,
        max: summary.max,
      };
    }
    return filterSummary;
  };

  const filterSummary = getFilterSummary(filter);

  // summary for setting handles on slider
  const [selectedSummaryForSlider, setSelectedSummaryForSlider]
    = useState<MinMax>();
  // summary with values for calling the API
  const [selectedSummary, setSelectedSummary] = useState(null);

  useEffect(() => {
    const selectedSummaryForSlider = getSelectedSummary(
      filter,
      selectedFilters,
      filterSummary,
    );
    setSelectedSummaryForSlider(selectedSummaryForSlider);
  }, [selectedFilters, filter]);

  useEffect(() => {
    if (filter.children.length <= 2) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [filter]);

  /**
   * @desc Gets object of points for rendering slider. Slider expects object notation explained in return statement
   * @param {object} parent filter node { children: [{ id: 5, value: 400}]}
   * @returns {object} { 20:20, 40:40, 100:100 }
   */
  const getSliderSpan = (node: any) => {
    return node.children.reduce((acc: any, currentChild: any) => {
      return { ...acc, [currentChild.value]: currentChild.value };
    }, {});
  };

  /**
   * @desc Gets span of filters component should return to be used by API
   * @param {object} minMax { min: number, max: number }
   * @returns {array}
   */
  const getCurrentFilterSpan = (minMax: { min: number; max: number }) => {
    return filter.children.filter(
      (child: any) => child.value >= minMax.min && child.value <= minMax.max,
    );
  };

  const hasSliderChanged = (minMax: MinMax, summary?: MinMax) => {
    if (minMax.min === minMax.max) {
      return false;
    }
    if (summary) {
      if (minMax.min === summary.min && minMax.max === summary.max) {
        return false;
      }
    }
    return true;
  };

  /**
   * @desc Runs on any change. Used to set selected values for slider display only.
   * Sets slider handles to correct values on reload
   * Cant run API call filterChange here as that would trigger multiple API calls load when user
   * @param {array} e [947, 1003]
   */
  const sliderChanged = (value: number | number[]) => {
    onChange?.(value);
    if (Array.isArray(value)) {
      const minMax = {
        min: value[0],
        max: value[1],
      };

      if (hasSliderChanged(minMax, selectedSummaryForSlider)) {
        setSelectedSummaryForSlider(minMax);
      }
    }
  };

  /**
   * @desc Runs on mouse change. This is used for triggering filterChange to call API with filter changes
   * @param {array} e [947, 1003]
   */
  const afterSliderChanged = (value: number | number[]) => {
    if (Array.isArray(value)) {
      const minMax = {
        min: value[0],
        max: value[1],
      };
      if (hasSliderChanged(minMax, selectedSummary || undefined)) {
        setSelectedSummary(selectedSummary);
        const result = getCurrentFilterSpan(minMax);
        filterChange?.(filter, result);
      }
    }
  };
  return selectedSummaryForSlider
    ? (
      <FilterSliderStyled>
        <Paragraph styleType="paragraph14">
          {`${fromLabel}: ${selectedSummaryForSlider.min}${filterSummary.unit} ${toLabel}: ${selectedSummaryForSlider.max}${filterSummary.unit}`}
        </Paragraph>
        <Range
          min={filterSummary.min}
          max={filterSummary.max}
          value={[selectedSummaryForSlider.min, selectedSummaryForSlider.max]}
          marks={getSliderSpan(filter)}
          step={null}
          onChange={sliderChanged}
          onAfterChange={afterSliderChanged}
          allowCross={false}
          disabled={isDisabled}
        />
      </FilterSliderStyled>
      )
    : null;
};
