import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { GridChild as DefGridChild } from '../grid-child/grid-child';

export const Container = styled.div`
  margin: ${spacing.xl} 0 ${spacing.s} auto;
  position: relative; /* anchor for ::before and ::after */

  &::after {
    background: ${colors.wibeGreen};
    content: '';
    display: block;
    height: 130px;
    left: 50%;
    position: absolute;
    top: -77px;
    width: 2px;
  }

  &::before {
    background: ${colors.wibeGreen};
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    margin-left: -49px;
    position: absolute;
    top: -77px;
    width: 178px;
  }
`;

export const Label = styled.p`
  margin-top: 24px;
  text-align: center;
`;

export const StyledImage = styled.img`
  height: auto;
  width: 100%;
`;

export const OuterGridChild = styled(DefGridChild).attrs({
  columnSpan: [
    { columns: 8 },
    { breakpoint: 'mobileMax', columns: 8, start: 1 },
    { breakpoint: 'desktop', columns: 10, start: 2 },
  ],
})`
  background-color: ${colors.grey1};
  padding: ${spacing.l} ${spacing.s};

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${spacing.l} ${spacing.xl};
  }
`;
