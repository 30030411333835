import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

export const Container = styled.div`
  display: block;
  margin: ${spacing.l} auto;
  padding-left: ${spacing.s};
  padding-right: ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.xl} auto;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
`;

export const StyledVideo = styled.video`
  align-self: center;
  max-height: calc(100vh - 56px);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
`;

export const PlayButton = styled.button`
  background: none;
  background: rgb(234 234 234 / 50%);
  background-color: ${colors.wibeYellow};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 56px;
  left: 50%;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
  width: 56px;

  &.visible {
    opacity: 1;
    pointer-events: initial;
    transition: opacity 0.3s ease;
  }

  &::before {
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      background: ${colors.wibeYellowHover};
      content: '';
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transform: translateX(-100%);
      width: 100%;
      z-index: -1;
    }
  }

  &:hover::before {
    transform: translateX(0);
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    height: ${spacing.xl};
    width: ${spacing.xl};
  }
`;

export const PlayButtonIcon = styled.svg`
  height: 16.47px;
  width: 14.06px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    height: 28.23px;
    width: 24.13px;
  }

  path {
    fill: ${colors.wibeDarkGrey};
    opacity: 1;
  }
`;

export const Description = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: ${fontSizes.paragraph10};
  margin-top: ${spacing.s};
`;
export const VideoLabel = styled.div`
  color: ${colors.grey3};
  margin-right: ${spacing.m};
`;

export const VideoTitle = styled.p`
  line-height: ${lineHeights.header4};
  margin-top: 1px;
`;
