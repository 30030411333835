import styled from 'styled-components';

import { clampedContainer, spacing } from '@hultafors/wibe/helpers';

import { paragraph12Style } from '../paragraph/paragraph.styled';

import DividerSvg from './divider.svg';

export const BreadcrumbsStyled = styled.div`
  overflow-x: auto;
`;

export const Divider = styled(DividerSvg).attrs({
  'aria-hidden': true,
  'focusable': false,
  'width': 16,
  'height': 16,
})`
  width: ${spacing.s};
  height: ${spacing.s};
`;

export const Inner = styled.div`
  ${clampedContainer('desktopMediumLarge')};
  display: flex;
  align-items: center;
  gap: ${spacing.s};
  height: ${spacing.l};

  a {
    position: relative;
    ${paragraph12Style};
    white-space: nowrap;
    line-height: ${spacing.s};
  }
`;
