import styled from 'styled-components';

import { colors, spacing } from '@hultafors/wibe/helpers';

export const StyledCertificationBox = styled.div`
  background: ${colors.wibeSuperlightSteel};
  padding: ${spacing.s};

  img {
    margin-bottom: ${spacing.s};
    width: 100px;
    height: 34.3734px;
  }
`;
