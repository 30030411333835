import { createContext, useMemo } from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import { ApiSettings, FavoriteItem } from '@hultafors/shared/types';

import { createProductFromFavorite } from '@hultafors/wibe/api';
import { WibeProduct } from '@hultafors/wibe/types';

export type StoredFavorite = FavoriteItem | WibeProduct;

export function isWibeladdersProduct(
  item: StoredFavorite,
): item is WibeProduct {
  return Object.prototype.hasOwnProperty.call(item, 'productId');
}

interface FavoritesContextValue {
  favorites: WibeProduct[];
  toggleFavorite(favorite: WibeProduct): void;
  isFavorite(favorite: WibeProduct): boolean;
  inFavorites(sku: string): boolean;
  clearFavorites(): void;
}

export const FavoritesContext = createContext<FavoritesContextValue>({
  favorites: [],
  toggleFavorite: () => {
    return;
  },
  isFavorite: () => {
    return false;
  },
  inFavorites: () => {
    return false;
  },
  clearFavorites: () => {
    return;
  },
});

interface FavoritesProviderProps {
  children?: React.ReactNode;
  settings: ApiSettings;
}

export const FavoritesProvider: React.FC<FavoritesProviderProps> = ({
  children,
  settings,
}) => {
  const storedFavorites = useLocalStorageValue<StoredFavorite[]>(
    `wibe_favorites-${settings?.market ?? 'com'}`,
    {
      initializeWithValue: false,
      defaultValue: [],
    },
  );

  const favorites = useMemo(() => {
    return (
      storedFavorites?.value?.map((item) => {
        if (isWibeladdersProduct(item)) {
          return item;
        }
        return createProductFromFavorite(item);
      }) || []
    );
  }, [storedFavorites]);

  const toggleFavorite = (product: WibeProduct) => {
    function itemFinder(item: WibeProduct) {
      return item.productId === product.productId;
    }
    function itemFilter(item: WibeProduct) {
      return item.productId !== product.productId;
    }
    storedFavorites.set(
      favorites.find(itemFinder)
        ? favorites.filter(itemFilter)
        : [...favorites, product],
    );
  };

  /**
   * Checks if a FavoriteItem is in the favorites list
   */
  const isFavorite = ({ productId }: WibeProduct): boolean => {
    function itemFinder(item: WibeProduct) {
      return item.productId === productId;
    }
    return !!favorites.find(itemFinder);
  };

  /**
   * Checks if a sku is in the favorites list
   */
  const inFavorites = (productId: string): boolean => {
    function itemFinder(item: WibeProduct) {
      return item.productId === productId;
    }
    return !!favorites.find(itemFinder);
  };

  const clearFavorites = () => {
    storedFavorites.set([]);
  };

  return (
    <FavoritesContext.Provider
      value={{
        favorites: favorites || [],
        toggleFavorite,
        clearFavorites,
        isFavorite,
        inFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
