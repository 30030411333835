import { useState } from 'react';

import { useKey } from 'react-use';

import { colors } from '@hultafors/wibe/helpers';

import { Dialog } from '../dialog/dialog';
import { ImageBlock } from '../image-block/image-block';
import { Loader } from '../loader/loader';

import { ProductImageDialogStyled } from './product-image-dialog.styled';

interface ProductImageDialogProps {
  images: any[];
  onClick?(): void;
}

export const ProductImageDialog: React.FC<ProductImageDialogProps> = ({
  images,
  onClick,
}) => {
  const [loading, setLoading] = useState(true);
  useKey('Escape', onClick);

  const formatColor = (color: string) => {
    return encodeURI(color.substring(1, color.length));
  };

  const firstImageLoaded = () => {
    setLoading(false);
  };

  return (
    <ProductImageDialogStyled>
      <Dialog
        onClose={onClick}
        closeButtonLabel={(
          <ImageBlock
            src="/assets/gfx/close_24.svg"
            width={24}
            height={24}
            alt="Close Icon"
          />
        )}
      >
        {loading && <Loader fullscreen />}
        {images.map((image, index) => (
          <div
            key={`image${index}`}
            className={(loading ? 'Hide ' : 'Show ') + 'DialogImage'}
          >
            <img
              src={`${image?.url || ''}${
                image?.url || ''.includes('.svg')
                  ? ''
                  : `?h=1500&bgcolor=${formatColor(colors.white)}&format=jpg`
              }`}
              alt={image.caption}
              title={image.caption}
              onLoad={index === 0 ? firstImageLoaded : () => null}
            />
          </div>
        ))}
      </Dialog>
    </ProductImageDialogStyled>
  );
};
