import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

export const EmptyProductStyled = styled.div`
  margin-bottom: ${spacing.side};

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    margin-bottom: ${spacing.m};
  }

  .ImagePlaceholder {
    background: ${colors.grey1};
    display: flex;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      height: 0;
      padding-bottom: 100%;
      width: 1px;
    }
  }

  .ProductInfoPlaceholder {
    display: flex;
    flex-direction: column;
    padding: 10px 0 0;

    span {
      background-color: ${colors.grey2};
      display: flex;
      height: 14px;
      margin-bottom: 10px;

      &:nth-of-type(1) {
        width: 150px;
      }

      &:nth-of-type(2) {
        width: 110px;
      }
    }
  }
`;
