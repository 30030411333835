import styled from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

import { Paragraph } from '../paragraph/paragraph';

export const FavoritesStyled = styled.div`
  .Clear {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: ${fontSizes.paragraph12};
    line-height: ${lineHeights.$paragraph12};
    margin-bottom: ${spacing.m};
    transition: 0.2s ease;

    .RemoveFilterIcon {
      height: ${spacing.s};
      margin-left: ${spacing.xs};
      width: ${spacing.s};
    }

    &:hover {
      color: ${colors.systemBlack};

      svg path {
        fill: ${colors.systemBlack};
      }
    }
  }
`;

export const ClearLabel = styled(Paragraph).attrs({
  styleType: 'paragraph16',
  as: 'span',
})`
  margin: 0;
`;

export const HeaderInner = styled.div`
  display: flex;
  gap: var(--min-tap-space);
  align-items: center;
`;
