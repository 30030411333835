import styled, { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

import { Paragraph } from '../paragraph/paragraph';

export const Container = styled.div`
  margin-inline: ${spacing.s};
  margin-block: ${spacing.l};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-inline: auto;
    margin-block: ${spacing.xl};
    width: 66vw;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 50vw;
  }
`;

export const ImageContainer = styled.div<{
  $aspectRatio?: CSSProperties['aspectRatio'];
}>`
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || 'inherit'};
  position: relative;
`;

export const StyledImage = styled.img`
  height: auto;
  max-width: 100%;
  min-width: 100%;
`;

export const StyledParagraph = styled(Paragraph)`
  margin-top: ${spacing.m};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.m};
`;
