import styled, { css } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import {
  Color,
  colors,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

export type HTagStyleType =
  | 'mega1'
  | 'header1'
  | 'header2'
  | 'header3'
  | 'header4'
  | 'header5'
  | 'heroIngress'
  | 'label';

interface ResponsiveStyleType {
  breakpoint?: Breakpoint;
  styleType: HTagStyleType;
}

export type HTagStyleTypeProp = HTagStyleType | ResponsiveStyleType[];

interface StyledHTagProps {
  $color?: Color;
  $center?: boolean;
  $border?: boolean;
  $styleType: HTagStyleTypeProp;
}

const fontStyle = (styleType: HTagStyleType) => {
  switch (styleType) {
    case 'mega1':
      return css`
        font-size: ${fontSizes.mega1};
        line-height: ${lineHeights.mega1};
        font-weight: ${fontWeights.light};

        @media screen and (max-width: ${breakpoints.mobileMax}) {
          font-size: ${fontSizes.header2};
          line-height: ${lineHeights.header2};
          font-weight: ${fontWeights.light};
        }
      `;
    case 'header1':
      return css`
        font-size: ${fontSizes.header1};
        line-height: ${lineHeights.header1};
        font-weight: ${fontWeights.light};
      `;
    case 'header2':
      return css`
        font-size: ${fontSizes.header2};
        line-height: ${lineHeights.header2};
        font-weight: ${fontWeights.light};
      `;
    case 'header3':
      return css`
        font-size: ${fontSizes.header3};
        line-height: ${lineHeights.header3};
        font-weight: ${fontWeights.light};
        letter-spacing: ${letterSpacings.header3};
      `;
    case 'header4':
      return css`
        font-size: ${fontSizes.header4};
        line-height: ${lineHeights.header4};
        font-weight: ${fontWeights.light};
      `;
    case 'header5':
      return css`
        font-size: ${fontSizes.header5};
        line-height: ${lineHeights.header5};
        font-weight: ${fontWeights.light};
        text-transform: none;
      `;
    case 'label':
      return css`
        font-size: ${fontSizes.paragraph16};
        line-height: ${lineHeights.paragraph16};
        font-weight: ${fontWeights.medium};
        letter-spacing: ${letterSpacings.heroLabel};
      `;
    case 'heroIngress':
      return css`
        font-size: ${fontSizes.header4};
        font-weight: ${fontWeights.medium};
        letter-spacing: ${letterSpacings.header4};
        line-height: ${lineHeights.header4};
      `;
    default:
      return css`
        font-size: ${fontSizes.header1};
        line-height: ${lineHeights.header1};
        font-weight: ${fontWeights.medium};
      `;
  }
};

const breakpointMapper = ({ breakpoint, styleType }: ResponsiveStyleType) =>
  breakpoint
    ? css`
        @media screen and (min-width: ${breakpoints[breakpoint]}) {
          ${fontStyle(styleType)}
        }
      `
    : fontStyle(styleType);

const handleStyleType = (styleType: HTagStyleTypeProp) => {
  return Array.isArray(styleType)
    ? styleType.map(breakpointMapper)
    : fontStyle(styleType);
};

export const StyledHTag = styled.h1<StyledHTagProps>`
  color: ${({ $color = 'systemBlack' }) => colors[$color]};
  text-align: ${({ $center }) => ($center ? 'center' : 'initial')};
  border-bottom: ${({ $border }) =>
    $border ? `thin solid ${colors.grey2}` : 'none'};
  padding-bottom: ${({ $border }) => ($border ? spacing.m : '0px')};
  hyphens: auto;
  widows: 3;
  orphans: 3;
  ${({ $styleType }) => handleStyleType($styleType)}
`;
