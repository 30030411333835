import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { Divider } from '../divider/divider';
import { Icon } from '../icon/icon';

const arrowWidth = 24;

export const ProductSliderStyled = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  *:focus {
    outline: none;
  }

  .Counter {
    align-items: center;
    bottom: 0;
    display: flex;
    height: auto;
    justify-content: flex-end;
    left: 0;
    padding-bottom: ${spacing.xs};
    padding-left: ${spacing.s};
    padding-right: ${spacing.s};
    position: absolute;
    width: 100%;
  }

  .slick-slider {
    box-sizing: border-box;
    display: block;
    height: 100%;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    user-select: none;
  }

  .slick-prev,
  .slick-next {
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  .slick-slider.article {
    .slick-prev,
    .slick-next {
      background: url('/assets/gfx/chevronRight.svg') center center no-repeat
        ${colors.white};
      background-position: center;
      border-radius: 100%;
      bottom: -${spacing.side};
      height: ${arrowWidth}px;
      position: absolute;
      top: calc(50% - ${arrowWidth / 2}px);
      width: ${arrowWidth}px;
      z-index: 1;
    }

    .slick-prev {
      background-size: ${arrowWidth}px;
      left: 0;
      margin-left: ${spacing.s};
      transform: rotate(180deg);
    }

    .slick-next {
      background-size: ${arrowWidth}px;
      margin-right: ${spacing.s};
      right: 0;
    }
  }

  .slick-list {
    display: block;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-track {
    display: block;
    left: 0;
    position: relative;
    top: 0;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    height: 100%;
    transform: translate3d(0, 0, 0);
  }

  .slick-track::before,
  .slick-track::after {
    content: '';
    display: table;
  }

  .slick-track::after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    > div {
      height: 100%;
      position: relative;
    }
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    border: thin solid transparent;
    display: block;
    height: auto;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
  margin-left: -${spacing.s};
  width: calc(100% + 2 * ${spacing.s});

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-left: -${spacing.l};
    width: calc(100% + 2 * ${spacing.l});
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: none;
  }
`;

export const SliderAndContentWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    grid-template-columns: 100px 1fr;
    gap: ${spacing.m};
  }
`;

export const SliderAndCounterWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;

  @media screen and (min-width: ${breakpoints.largeMobile}) {
    aspect-ratio: 4 / 3;
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    /* aspect-ratio: 1 / 1; */
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    aspect-ratio: 5 / 4;
    border: thin solid ${colors.grey2};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 1 / 1;
  }
`;

export const ThumbnailWrapper = styled.div`
  display: none;
  overflow: hidden scroll;
  scrollbar-width: none; /* Firefox */
  will-change: scroll-position;
  height: 100%;
  max-height: 100vw;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    max-height: calc(80vw - 150.4px);
    display: grid;
    grid-template-rows: auto;
    gap: ${spacing.xs};
    position: relative;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    max-height: calc(50vw - 164px);
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    max-height: 554px;
  }
`;
export const Thumbnail = styled.button<{ $active?: boolean }>`
  appearance: none;
  background-color: transparent;
  border: thin solid ${({ $active }) => ($active ? colors.grey3 : colors.grey2)};
  border-radius: 0;
  display: block;
  height: 100px;
  overflow: hidden;
  padding: ${spacing.xs};
  width: 100px;
  position: relative;
  aspect-ratio: 1 / 1;
  cursor: pointer;

  img {
    /* height: 100%; */
    object-fit: contain;

    /* width: 100%; */
  }
`;

export const ImgWrapper = styled.button`
  appearance: none;
  border: none;
  border-radius: 0;
  display: flex;
  height: 100%;
  overflow: hidden;
  padding: 40px;
  position: relative;
  cursor: pointer;
  width: 100%;
  aspect-ratio: 1 / 1;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  background-color: #fff;

  > span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const ZoomIcon = styled(Icon).attrs({ svg: 'zoom', width: 24 })`
  position: absolute;
  right: ${spacing.xs};
  top: ${spacing.xs};
  color: ${colors.grey4};
  z-index: 1;
  pointer-events: none;
`;

export const ModalImageWrapper = styled.div`
  position: relative;
  aspect-ratio: 1 / 1;
  max-height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* margin-block-start: 40px; */

  /* margin-inline: 40px; */
  img {
    object-fit: contain;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s};
`;
