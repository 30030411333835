import styled from 'styled-components';

import { fontSizes, spacing } from '@hultafors/wibe/helpers';

export const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 0 ${spacing.m};
`;

export const Title = styled.p`
  font-size: ${fontSizes.header2};
  width: 100%;
`;

export const List = styled.ul`
  list-style: none;
  margin-top: ${spacing.m};
  padding: 0;
  width: 100%;
`;

export const ListItem = styled.li`
  & + & {
    margin-top: ${spacing.m};
  }
`;
