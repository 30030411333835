import styled from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

export const Search = styled.div`
  opacity: 1;
  transition: 0.1s opacity ease-in-out;
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;

  input {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    width: 100%;
    border: 0;
    font-weight: ${fontWeights.regular};
    line-height: ${lineHeights.header4};
    font-size: ${fontSizes.paragraph14};
    box-sizing: border-box;
    appearance: none;
  }

  &.is-hidden {
    opacity: 0;
    transition: 0.05s opacity ease-in-out;
  }
`;

export const ProductWrapper = styled.div`
  /* display: flex;
  flex-direction: column; */
  position: fixed;
  top: 64px; /* Height of Nav */
  width: 100%;
  left: 0;
  background-color: ${colors.white};
  padding: ${spacing.m};
  max-height: 100%;
  overflow-x: hidden;
`;
