import {
  BigPlugFragment,
  CategoryGridFragment,
  ColorPlugsFragment,
  ContactInfoBlockFragment,
  DividedTitleAndTextFragment,
  GreyInfoBoxFragment,
  HeroFragment,
  OfficesBlockFragment,
  PagePlugsFragment,
  SimpleTextAndCtaFragment,
  TitleAndTextBlockFragment,
  VideoFragment,
  VisibleSeoBlockFragment,
} from '../dato';

export function isBigPlug(item: any): item is BigPlugFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'BigPlugRecord';
}

export function isCategoryGrid(item: any): item is CategoryGridFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'CategoryGridRecord';
}

export function isColorPlugs(item: any): item is ColorPlugsFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ColorPlugsBlockRecord';
}

export function isContactInfoBlock(
  item: any,
): item is ContactInfoBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ContactInfoBlockRecord';
}

export function isDividedTitleAndText(
  item: any,
): item is DividedTitleAndTextFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DividedTitleAndTextRecord';
}

export function isGreyInfoBox(item: any): item is GreyInfoBoxFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'GreyInfoBoxRecord';
}

export function isHero(item: any): item is HeroFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'HeroRecord';
}
export function isOfficesBlock(item: any): item is OfficesBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'OfficesBlockRecord';
}
export function isPagePlugs(item: any): item is PagePlugsFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'PagePlugsBlockRecord';
}

export function isSimpleTextAndCta(
  item: any,
): item is SimpleTextAndCtaFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'SimpleTextAndCtaRecord';
}

export function isTitleAndTextBlock(
  item: any,
): item is TitleAndTextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TitleAndTextBlockRecord';
}
export function isVideo(item: any): item is VideoFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VideoRecord';
}
export function isVisibleSeoBlock(item: any): item is VisibleSeoBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VisibleSeoBlockRecord';
}
