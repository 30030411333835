import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  clampedContainer,
  colors,
  spacing,
} from '@hultafors/wibe/helpers';

import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

export const ModelSectionStyled = styled.section`
  background: ${colors.grey1};
  padding-bottom: ${spacing.l};
  padding-top: ${spacing.l};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.m};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-bottom: ${spacing.xl};
    padding-top: ${spacing.xl};
  }

  > * {
    ${clampedContainer('desktopMedium')}
  }

  /* .Models {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
    1376
    564 x 2
    1144
    16
  } */
`;

export const Header = styled.div`
  display: flex;
  gap: ${spacing.xs};
`;

export const Models = styled.div`
  display: grid;
  gap: ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(2, 1fr);
  }

  &:empty {
    display: none;
  }
`;

export const Title = styled(HTag).attrs({
  type: 'h2',
  styleType: 'header4',
  color: 'wibeDarkGrey',
})``;

export const ProductName = styled(Paragraph).attrs({
  styleType: 'paragraph14',
  color: 'wibeDarkGrey',
})`
  &::before {
    content: '– ';
  }
`;

export const ErrorMessage = styled(Paragraph)``;
