import { Fragment, useState } from 'react';

import { Variants } from 'framer-motion';
import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';
import { NameValue, ParsedImage } from '@hultafors/shared/types';

import { createProductImageSrc } from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';
import { WibeProduct } from '@hultafors/wibe/types';

import { Icon } from '../icon/icon';
import { Modal } from '../modal/modal';
import { Paragraph } from '../paragraph/paragraph';

import {
  FeatureDefinition,
  FeatureList,
  FeatureTerm,
  GoodWorkEnvironmentChoiceDesktop,
  GoodWorkEnvironmentChoiceMobile,
  ImageButton,
  ImageWrapper,
  ModalImageWrapper,
  ModelName,
  ModelStyled,
  MotionDiv,
  StyledTextButton,
  Value,
  ZoomIcon,
} from './model.styled';

interface ModelProps {
  model: WibeProduct;
  heightFieldLabel: string;
  measureImage?: ParsedImage;
}

export const Model: React.FC<ModelProps> = ({
  measureImage,
  model,
  heightFieldLabel,
}) => {
  const { global } = useGlobal();
  const [active, setActive] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState<ParsedImage>();

  const variants: Variants = {
    closed: { height: '85px' },
    open: { height: 'auto' },
  };

  const handleImageModal = (image: ParsedImage) => {
    setModalImage(image);
    setModalVisible(true);
  };

  const openImageModal = () => {
    handleImageModal({ url: model.image || '', alt: model.name });
  };

  function featureMapper({ name, value }: NameValue) {
    return (
      <Fragment key={name}>
        <FeatureTerm>{name === 'H' ? heightFieldLabel : name}</FeatureTerm>
        <FeatureDefinition>{value}</FeatureDefinition>
      </Fragment>
    );
  }

  if (!model) {
    return null;
  }
  const sizes = [
    `(min-width: ${breakpoints.desktopSmall}) 812px`,
    `(min-width: ${breakpoints.mobileMax}) calc(100vw - 48px)`,
    '100vw',
  ].join(', ');

  return (
    <ModelStyled $active={active}>
      {modalImage?.url && modalVisible && (
        <Modal
          isOpen={modalVisible}
          setModalVisible={setModalVisible}
          wrapperStyle={{ height: '100%' }}
        >
          <ModalImageWrapper>
            {modalImage.url.endsWith('.svg')
              ? (
                <img src={modalImage.url} alt={modalImage.alt || ''} />
                )
              : (
                <Image
                  src={createProductImageSrc(modalImage.url)}
                  alt={modalImage?.alt || ''}
                  fill
                  sizes={sizes}
                />
                )}
          </ModalImageWrapper>
        </Modal>
      )}
      <div className="Top">
        {model.image
          ? (
            <ImageButton onClick={openImageModal}>
              <ZoomIcon />
              <ImageWrapper>
                <Image
                  src={createProductImageSrc(model.image)}
                  alt={model.name}
                  width={72}
                  height={72}
                />
              </ImageWrapper>
            </ImageButton>
            )
          : (
            <div className="Image">
              <Image
                src="/assets/gfx/noImage.png"
                alt={model.name}
                width={72}
                height={72}
              />
            </div>
            )}
        <div className="PriceWrapper">
          {model.name && <ModelName>{model.name}</ModelName>}
          {model.price?.value.min
            ? (
              <div className="Price">
                <Value>
                  {Math.round(model.price.value.min)}
                  {' '}
                  {model.price.currency}
                </Value>
                <Paragraph color="grey4" styleType="paragraph10">
                  {global?.rrpLabel || ''}
                </Paragraph>
              </div>
              )
            : model.priceWithVat?.value.min
              ? (
                <div className="Price">
                  <Value>
                    {Math.round(model.priceWithVat.value.min)}
                    {' '}
                    {model.priceWithVat.currency}
                  </Value>
                  <Paragraph color="grey4" styleType="paragraph10">
                    {global?.rrpIncludingVatLabel || ''}
                  </Paragraph>
                </div>
                )
              : null}

          {model.isWorkingEnvironment && <GoodWorkEnvironmentChoiceDesktop />}
        </div>
        {!!model.modelFeatures?.length && (
          <button
            className="Toggle-button"
            onClick={() => setActive(!active)}
            aria-label={
              active ? global?.close || '' : global?.readMoreButtonLabel || ''
            }
          >
            {active && <Icon svg="chevron-up" />}
            {!active && <Icon svg="chevron-down" />}
          </button>
        )}
      </div>
      {model.isWorkingEnvironment && <GoodWorkEnvironmentChoiceMobile />}

      <MotionDiv
        initial={false}
        animate={active ? 'open' : 'closed'}
        variants={variants}
      >
        <FeatureList>
          {model.modelFeatures?.map(featureMapper)}
          <FeatureTerm>{global?.skuLabel || ''}</FeatureTerm>
          <FeatureDefinition>{model.sku}</FeatureDefinition>
        </FeatureList>
        {measureImage?.url && (
          <ImageButton
            onClick={() => handleImageModal(measureImage)}
            className="Measure-Image-Wrapper"
          >
            <StyledTextButton>
              <Icon svg="zoom" width={12} />
            </StyledTextButton>
            <div className="Measure-Image Image">
              <Image
                src={measureImage.url}
                alt={model.name || 'measure image'}
                width={72}
                height={72}
              />
            </div>
          </ImageButton>
        )}
      </MotionDiv>
    </ModelStyled>
  );
};
