import { MouseEvent, useEffect, useRef, useState } from 'react';

import { FILTER_TYPE } from '@hultafors/wibe/api';

import { CheckboxFilter } from '../checkbox-filter/checkbox-filter';
import { FilterSlider } from '../filter-slider/filter-slider';
import { TextButton } from '../text-button/text-button';

import { HighlightedFilterStyled } from './highlighted-filters.styled';

export const HighlightedFilters: React.FC<HighlightedFiltersProps> = ({
  selectedFilters,
  filters,
  filterChange,
  fromLabel,
  toLabel,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: Event) => {
    if (
      ref.current
      && event.target
      && !ref.current?.contains(event.target as Node)
    ) {
      setActiveIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const toggleActive = (event: Event | MouseEvent, index: number) => {
    event.preventDefault();

    const currentIndex = activeIndex === index ? null : index;
    setActiveIndex(currentIndex);
  };

  const isSelectionUnderParent = (parent: any) => {
    return selectedFilters.find((selectedParent) => {
      if (selectedParent.id === parent.id) {
        return selectedParent.children.length > 0;
      }
    });
  };

  const renderFilter = (node: any, index: number) => {
    if (node.type === FILTER_TYPE.ENUMERATED) {
      return (
        <CheckboxFilter
          filterNode={node}
          filterChange={filterChange}
          selectedFilters={selectedFilters}
          isActive={activeIndex === index}
        />
      );
    } else {
      if (node.children.length > 1) {
        return (
          <div
            className={`SubFilters ${activeIndex === index ? 'Active ' : ''}`}
          >
            <FilterSlider
              filter={node}
              onChange={(e) => filterChange(e, node)}
              fromLabel={fromLabel}
              toLabel={toLabel}
              filterChange={filterChange}
              selectedFilters={selectedFilters}
            />
          </div>
        );
      }
    }
    return '';
  };

  /**
   * @desc Dont render numeric filter when we only have one child. It´s a slider that requires >=2 children to be useful
   * @param {object} node
   * @returns {boolean}
   */
  const shouldRenderFilter = (node: any) => {
    if (node.type === FILTER_TYPE.NUMERIC && node.children.length <= 1) {
      return false;
    }
    return true;
  };

  return (
    <HighlightedFilterStyled>
      <div ref={ref} className="DropDownFilters">
        {filters.map((parent, i) => {
          if (shouldRenderFilter(parent)) {
            return (
              <div key={`Filter-${i}`} className="FilterWrapper">
                <TextButton
                  className={`TextAndIcon MobileFilter ${
                    activeIndex === i ? 'Active ' : ''
                  } ${isSelectionUnderParent(parent) ? 'ChildSelected' : ''}`}
                  onClick={(e) => toggleActive(e, i)}
                >
                  <span className="FilterName">{parent.name}</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.64645 9.35356L6.35356 8.64645L12 14.2929L17.6465 8.64645L18.3536 9.35356L12 15.7071L5.64645 9.35356Z"
                      fill="#303E47"
                    />
                  </svg>
                </TextButton>

                {renderFilter(parent, i)}
              </div>
            );
          } else {
            return '';
          }
        })}
      </div>
    </HighlightedFilterStyled>
  );
};

interface HighlightedFiltersProps {
  selectedFilters: any[];
  filters: any[];
  filterChange(a: any, b?: any, c?: any): void;
  fromLabel?: string;
  toLabel?: string;
}
