import { Fragment } from 'react';

import Markdown from 'markdown-to-jsx';
import Image from 'next/image';
import Link from 'next/link';

import { spacing } from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';

import { Flex } from '../flex/flex';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { Icon } from '../icon/icon';
import { RouterLink } from '../router-link/router-link';
import { SubscribeArea } from '../subscribe-area/subscribe-area';
import { TextButton } from '../text-button/text-button';

import {
  BottomFooterContainer,
  FooterStyled,
  Logo,
  SiteLogoWrapper,
  SubscribeAreaWrapper,
  TopWrapper,
} from './footer.styled';

export const Footer: React.FC = () => {
  const { footer, global } = useGlobal();

  const handleOnPressSettings = () => {
    // TODO fix TS error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CookieScript.instance.show();
  };

  const brandMapper = (item: any, index: number, all: any[]) => {
    return (
      <span key={`Company-${index}`}>
        <Link className="BrandLink" href={item.url}>
          {item.title}
        </Link>
        {index < all.length - 1 && <>|</>}
      </span>
    );
  };

  const socialMediaMapper = (item: any, index: number) => {
    return item.url
      ? (
        <Link
          key={`SocialMedia-${index}`}
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={item.icon.title}
        >
          <img
            src={item.icon.url}
            alt={item.icon.alt}
            width="32"
            height="32"
            aria-hidden={true}
          />
        </Link>
        )
      : null;
  };

  const menuMapper = (item: any, index: number) => {
    return (
      <Fragment key={`Company-${index}`}>
        <RouterLink to={item.url} variant="white" clean small>
          {item.title}
        </RouterLink>
        <span>{'\u00A0|\u00A0'}</span>
      </Fragment>
    );
  };

  return global && footer
    ? (
      <FooterStyled>
        <Grid columns={[{ columns: 4 }, { breakpoint: 'desktop', columns: 12 }]}>
          <GridChild>
            <TopWrapper align="flex-start" margin={`0 0 ${spacing.l}`}>
              {global?.siteLogo?.url
              && (
                <Logo>
                  <SiteLogoWrapper>
                    <Image
                      src={global.siteLogo.url}
                      alt=""
                      width={140}
                      height={60}
                    />
                  </SiteLogoWrapper>
                </Logo>
              )}
              <Flex>
                {global?.urlToPartnerPortal && (
                  <Link
                    href={global?.urlToPartnerPortal || ''}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="PartnerLoginLink"
                  >
                    {footer.partnerLogin}
                    <Icon svg="partner-login" />
                  </Link>
                )}
              </Flex>
            </TopWrapper>
          </GridChild>

          <GridChild
            columnSpan={[{ columns: 4 }, { breakpoint: 'desktop', columns: 5 }]}
            style={{ marginBottom: spacing.l }}
          >
            <Flex direction="column" align="flex-start">
              {footer.hultaforsBrands.length > 0 && (
                <Markdown
                  options={{ forceBlock: true }}
                  className="markdown-wrapper"
                >
                  {footer.introText || ''}
                </Markdown>
              )}
              <div className="HultaforsBrands">
                {footer.hultaforsBrands.map(brandMapper)}
              </div>
              <Markdown
                className="markdown-wrapper MoreInfoText"
                options={{ forceBlock: true }}
                style={{ marginTop: spacing.s }}
              >
                {footer.moreInfoText || ''}
              </Markdown>
            </Flex>
          </GridChild>

          {footer.userEmailFormUrl && (
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'desktop', columns: 3, start: 10 },
              ]}
              style={{ marginBottom: spacing.xl }}
            >
              <SubscribeAreaWrapper>
                <SubscribeArea
                  subscribeInfoText={footer.subscribeInfoText || ''}
                  subscribeButtonText={footer.subscribeButtonText || ''}
                  userEmailFormUrl={footer.userEmailFormUrl || ''}
                  userEmailFormImage={footer.userEmailFormImage || ''}
                />
              </SubscribeAreaWrapper>
            </GridChild>
          )}

          <GridChild>
            <BottomFooterContainer>
              <Flex className="SoMeRow">
                {footer.socialMediaMenu.map(socialMediaMapper)}
              </Flex>
              <Flex align="baseline">
                {footer.menu.map(menuMapper)}
                <TextButton
                  clean
                  variant="white"
                  small
                  onClick={() => {
                    handleOnPressSettings();
                  }}
                >
                  {footer.cookieSettings}
                </TextButton>
              </Flex>
            </BottomFooterContainer>
          </GridChild>
        </Grid>
      </FooterStyled>
      )
    : null;
};
