import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { clampedContainer, spacing } from '@hultafors/wibe/helpers';

import { Paragraph } from '../paragraph/paragraph';
const Skeleton = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Skeleton),
);

export const RelatedProductsWithTabsStyled = styled.section`
  ${clampedContainer()};
  margin-block: ${spacing.l};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block: ${spacing.xl};
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  max-width: 335px;
`;

export const Tabs = styled.div`
  margin-bottom: ${spacing.m};
`;

export const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${spacing.l};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Intro = styled(Paragraph).attrs({ styleType: 'header4' })`
  margin-block-end: ${spacing.l};

  &:empty {
    display: none;
  }
`;

export const SkeletonStyled = styled(Skeleton)`
  width: 100%;
  height: calc(448px + 70px);
`;

export const SkeletonWrapper = styled.div`
  div:nth-child(1) {
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  div:nth-child(2) {
    width: 66%;
    height: 24px;
    margin-block-start: 16px;
  }

  div:nth-child(3) {
    width: 33%;
    height: 18px;
    margin-block: 4px 8px;
  }
`;
