import { useRef, useState } from 'react';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import {
  Container,
  PlayButton,
  PlayButtonIcon,
  StyledVideo,
  VideoWrapper,
} from './video.styled';

interface VideoProps {
  src: any;
  thumbnail?: any;
  autoPlay?: boolean;
}

export const Video: React.FC<VideoProps> = (props) => {
  const [paused, setPaused] = useState(true);
  const videoPlayer = useRef<HTMLVideoElement>(null);

  const handleOnPlay = () => {
    videoPlayer.current?.setAttribute('controls', 'true');
    setPaused(false);
  };

  function playVideo() {
    videoPlayer.current?.play();
    handleOnPlay();
  }

  return (
    <Container>
      <Grid columns={[{ columns: 8 }, { breakpoint: 'desktop', columns: 12 }]}>
        <GridChild
          columnSpan={[
            { columns: 12 },
            { breakpoint: 'mobileMax', columns: 8, start: 1 },
            { breakpoint: 'desktop', columns: 12, start: 1 },
          ]}
        >
          <VideoWrapper>
            <StyledVideo
              ref={videoPlayer}
              poster={
                props.thumbnail && props.thumbnail.url
                  ? props.thumbnail.url
                  : ''
              }
              autoPlay={props.autoPlay}
              onPlay={handleOnPlay}
            >
              <source src={`${props.src.url}`} />
              <p>
                Your browser doesn&apos;t support HTML5 video. Here is a
                {' '}
                <a href={props.src.url}>link to the video</a>
                {' '}
                instead.
              </p>
            </StyledVideo>
            <PlayButton
              title="play"
              onClick={playVideo}
              className={paused ? 'visible' : ''}
            >
              <PlayButtonIcon
                fill="none"
                viewBox="0 0 12 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m12 7-11.9999997 6.9282.00000061-13.8564036z" />
              </PlayButtonIcon>
            </PlayButton>
          </VideoWrapper>
        </GridChild>
      </Grid>
    </Container>
  );
};
