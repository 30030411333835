import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { HTag } from '../h-tag/h-tag';

interface StyledCategoryBoxProps {
  $dark?: boolean;
  $margin?: boolean;
}

export const StyledCategoryBox = styled(Link)<StyledCategoryBoxProps>`
  background-color: ${({ $dark }) =>
    $dark ? colors.wibeLightSteel : colors.wibeSuperlightSteel};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${spacing.m};
  padding-top: ${({ $margin }) => ($margin ? spacing.l : null)};
  transition: 0.2s background-color;

  &:hover {
    background-color: ${({ $dark }) =>
      $dark ? colors.wibeLightSteelHover : colors.wibeSuperlightSteelHover};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-top: ${({ $margin }) => ($margin ? spacing.m : null)};
  }
`;

export const ProdImage = styled.img`
  margin-bottom: ${spacing.side};
  width: 100%;
`;

export const CategoryName = styled(HTag).attrs({ type: 'h3' })`
  margin-top: auto;
`;

export const Arrow = styled.div`
  margin-top: ${spacing.xs};
`;
