import Image from 'next/image';

import {
  CampaignInfoFragment,
  ImageFragment,
  Maybe,
} from '@hultafors/wibe/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { HeroImage } from '../hero-image/hero-image';

import {
  BackgroundContainer,
  Container,
  ImageContainer,
  StyledParagraph,
} from './campaign-info.styled';

interface CampaignInfoProps {
  background?: Maybe<ImageFragment>;
  backgroundTablet?: Maybe<ImageFragment>;
  backgroundMobile?: Maybe<ImageFragment>;
  image: CampaignInfoFragment['image'];
  body?: Maybe<string>;
  ctaText?: Maybe<string>;
  ctaLink?: Maybe<string>;
  ctaIsLink?: boolean;
}

export const CampaignInfo: React.FC<CampaignInfoProps> = ({
  background,
  backgroundTablet,
  backgroundMobile,
  image,
  body,
}) => {
  const backgroundColor
    = background?.responsiveImage?.bgColor || 'rgba(0,0,0,0)';
  const style: React.CSSProperties = {
    textShadow: `0 0 0.5vw ${backgroundColor}`,
  };

  return (
    <Container>
      <BackgroundContainer>
        <HeroImage
          mobile={backgroundMobile}
          tablet={backgroundTablet}
          desktop={background}
        />
      </BackgroundContainer>
      <Grid className="content" columns={[{ columns: 12 }]} columnGap="s">
        <GridChild
          columnSpan={[
            { columns: 8, start: 3 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <ImageContainer>
            {(image?.responsiveImage?.src || image?.url)?.endsWith('.svg')
              ? (
                <img
                  src={image?.responsiveImage?.src || image?.url || ''}
                  alt={image?.alt || ''}
                />
                )
              : (
                <Image
                  src={image?.responsiveImage?.src || image?.url || ''}
                  alt={image?.alt || ''}
                  fill
                  placeholder={image?.responsiveImage?.base64 ? 'blur' : 'empty'}
                  blurDataURL={image?.responsiveImage?.base64 || ''}
                />
                )}
          </ImageContainer>
        </GridChild>
        <GridChild
          columnSpan={[
            { columns: 8, start: 3 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
            { breakpoint: 'desktop', columns: 8, start: 3 },
          ]}
        >
          <StyledParagraph styleType="paragraph16" style={style}>
            {body}
          </StyledParagraph>
        </GridChild>
      </Grid>
    </Container>
  );
};
