import { breakpoints } from '@hultafors/shared/constants';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import {
  StyledMaintenanceBlock,
  StyledMarkdown,
  Title,
} from './maintenance-block.styled';

interface MaintenanceBlockProps {
  data?: any;
}

export const MaintenanceBlock: React.FC<MaintenanceBlockProps> = ({ data }) => {
  return !data.enabled
    ? (
      <StyledMaintenanceBlock>
        <Grid maxWidth={breakpoints.maxPageWidth}>
          <GridChild
            columnSpan={[{ columns: 4 }, { breakpoint: 'mobileMax', columns: 5 }]}
          >
            <div className="content">
              {data.title && <Title>{data.title}</Title>}
              {data.body && <StyledMarkdown>{data.body}</StyledMarkdown>}
            </div>
          </GridChild>
        </Grid>
      </StyledMaintenanceBlock>
      )
    : null;
};
