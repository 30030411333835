import { useEffect, useState } from 'react';

import { useKey } from 'react-use';

import { useGlobal } from '@hultafors/wibe/hooks';
import { LanguageFragment } from '@hultafors/wibe/types';

import { Dialog } from '../dialog/dialog';
import { ImageBlock } from '../image-block/image-block';
import { Loader } from '../loader/loader';
import { TextButton } from '../text-button/text-button';

import { List, ListItem, Main, Title } from './market-selector-qr.styled';

export const MarketSelectorQR: React.FC = () => {
  const { languageSelector } = useGlobal();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onClose = () => setIsDialogOpen(false);

  useKey('Escape', onClose);

  useEffect(() => {
    if (
      new URLSearchParams(window.location.search || '').has('select-language')
    ) {
      setIsDialogOpen(true);
    }
  }, []);

  const languageMapper = ({ id, name, url }: LanguageFragment) => {
    return id && name && url
      ? (
        <ListItem key={`language-${id}`}>
          <TextButton
            icon="/assets/icons/placeTag24Px.svg"
            leftIcon={true}
            to={`${url}${window.location.pathname || '/'}`}
          >
            {name}
          </TextButton>
        </ListItem>
        )
      : null;
  };
  return isDialogOpen
    ? (
      <Dialog
        onClose={onClose}
        closeButtonLabel={
          <ImageBlock src="/assets/gfx/close_24.svg" alt="Close Icon" />
      }
        isFullscreen={false}
        cover={true}
      >
        <Main>
          {languageSelector
            ? (
              <>
                <Title>{languageSelector.title}</Title>
                <List>{languageSelector.languages.map(languageMapper)}</List>
              </>
              )
            : (
              <Loader />
              )}
        </Main>
      </Dialog>
      )
    : null;
};
