import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing, Spacing } from '@hultafors/wibe/helpers';

interface StyledDividerProps {
  $margin?: Spacing;
}

export const StyledDivider = styled.hr<StyledDividerProps>`
  border: 0;
  border-bottom: thin solid ${colors.grey2};
  margin-block: ${({ $margin = 'm' }) => spacing[$margin]};

  @media screen and (max-width: ${breakpoints.mobileMax}) {
    margin: ${({ $margin = 'm' }) => {
      if ($margin === 'xl') {
        return spacing.l + ' 0';
      }
      return spacing[$margin] + ' 0';
    }};
  }
`;
