import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  depth,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

import { Favorite as FavoriteRaw } from '../favorite/favorite';
import { Icon } from '../icon/icon';

interface ProductStyledProps {
  $noHover?: boolean;
  $noMargin?: boolean;
  $isSmall?: boolean;
  $inFavorites?: boolean;
}

export const ProductStyled = styled.article<ProductStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .ProductIconWrapper {
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    z-index: 2;
  }

  .ProductIcon {
    height: 24px;
    margin-bottom: ${spacing.s};
    margin-right: ${spacing.s};
    width: 24px;
  }

  .ProductInfo {
    padding: ${spacing.s} 0 0 0;
    position: relative;

    .ProductName {
      color: ${colors.grey5};
      display: block;
      font-size: ${fontSizes.paragraph16};
      font-weight: ${fontWeights.medium};
      line-height: ${lineHeights.paragraph16};
      text-decoration: none;
      transition: 0.2s ease;

      a {
        color: ${colors.grey5};
      }
    }
  }

  .Favorite {
    display: flex;
    position: absolute;
    right: ${spacing.xs};
    top: ${spacing.xs};

    svg {
      position: initial;
    }

    &.InFavorites {
      right: ${spacing.xs};
      top: ${spacing.xs};
    }
  }

  .Badge {
    left: 0;
    position: absolute;

    &.TopLeft {
      top: ${spacing.xs};
    }

    &.BottomLeft {
      bottom: ${spacing.xs};
    }
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    ${({ $noHover }) =>
      !$noHover
      && css`
        &:hover {
          .ImageWrapper img {
            transform: scale(1.05);
          }
        }
      `}
  }
`;

export const EsdImg = styled.img`
  bottom: ${spacing.s};
  height: auto;
  position: absolute;
  right: ${spacing.s};
  width: 32px;
  z-index: 2;
`;

export const UspList = styled.li`
  &:not(:first-child) {
    margin-top: 4px;
  }
`;

export const ImageWrapper = styled.div`
  aspect-ratio: 1;
  background-color: ${colors.white};
  border: thin solid ${colors.grey2};
  position: relative;
  z-index: 0;

  .HeightInformation {
    bottom: ${spacing.xs};
    left: ${spacing.xs};
    position: absolute;
    z-index: ${depth.elevated};
  }

  .Co2Label {
    position: absolute;
    z-index: 3;
    width: 85px;
    top: 0;
    padding: ${spacing.xs};
  }
`;
export const Co2Label = styled(Icon).attrs({ svg: 'reduced_co2' })`
  position: absolute;
  z-index: 3;
  top: ${spacing.xs};
  left: ${spacing.xs};
`;

export const Favorite = styled(FavoriteRaw)`
  display: flex;
  position: absolute;
  right: ${spacing.xs};
  top: ${spacing.xs};
`;

export const ProductPrice = styled.div`
  align-items: baseline;
  display: flex;
  margin: 0;
  margin-bottom: ${spacing.xs};
  margin-top: 4px;
  transition: 0.2s ease;

  .rrp {
    color: ${colors.grey4};
    margin-left: 8px;
  }

  p {
    margin: 0;
  }
`;
