import styled from 'styled-components';

import { spacing } from '@hultafors/wibe/helpers';

export const StyledFilterList = styled.div`
  margin-bottom: -${spacing.xs}; /* Negative margin to cancel button margin below. */
  button {
    margin-bottom: ${spacing.xs};
    margin-right: ${spacing.s};
  }
`;
