export const fontSizes = {
  mega2: '2.25rem',
  mega1: '3.5rem',
  paragraph10: '0.625rem',
  paragraph12: '0.75rem',
  paragraph14: '0.875rem',
  paragraph16: '1rem',
  header1: '2.5rem',
  header2: '1.875rem',
  header3: '1.5rem',
  header4: '1.25rem',
  header5: '1.125rem',
} as const;

export type FontSize = keyof typeof fontSizes;
