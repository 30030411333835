import { useId } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { breakpoints } from '@hultafors/shared/constants';

import { Spacing, spacing } from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';
import {
  isDocumentsPageLink,
  isInformationListPageLink,
  isInformationPageLink,
  isPagePlug,
  PagePlugItem,
  PagePlugsFragment,
} from '@hultafors/wibe/types';

import { Grid } from '../grid/grid';
import { Icon } from '../icon/icon';
import { TextButton } from '../text-button/text-button';

import {
  DocumentImage,
  ImageAspect,
  StyledGridChild,
  StyledPagePlugs,
  Title,
} from './page-plugs.styled';

interface PagePlugsProps extends Omit<PagePlugsFragment, 'plugs'> {
  marginBlockStart?: Spacing;
  withMargin?: boolean;
  style?: React.CSSProperties;
  plugs?: PagePlugItem[];
}

export const PagePlugs: React.FC<PagePlugsProps> = ({
  plugs,
  marginBlockStart,
  style,
  spacingBottom,
  spacingTop,
}) => {
  const uid = useId();
  const { global } = useGlobal();
  const { asPath } = useRouter();

  if (!plugs?.length) {
    return null;
  }

  const sizes = [
    `(min-width: ${breakpoints.desktopLarge}) 680px`,
    `(min-width: ${breakpoints.mobileMax}) calc(50vw - ${spacing.xs} - 2 * ${spacing.m})`,
    `calc(100vw - 2 * ${spacing.s})`,
  ].join(', ');

  function plugMapper(item: PagePlugItem) {
    const key = `PagePlug-${uid}-${item.id}`;
    if (isDocumentsPageLink(item)) {
      return (
        <Link key={key} href={`${asPath}/${item.slug}`}>
          <StyledGridChild>
            <ImageAspect>
              <DocumentImage>
                <Icon svg="file-24" />
              </DocumentImage>
            </ImageAspect>
            <Title>{item.pageHeader}</Title>
            <TextButton arrow>{global?.readMoreButtonLabel || ''}</TextButton>
          </StyledGridChild>
        </Link>
      );
    }
    const url
      = (isPagePlug(item) ? item.url : `${asPath}/${item.slug}`) || asPath;
    const image
      = (isInformationListPageLink(item) || isInformationPageLink(item)
        ? item.teaserImage || item.image
        : item.image) || global.fallbackImage;
    const title
      = isInformationListPageLink(item) || isInformationPageLink(item)
        ? item.teaserTitle || item.title
        : item.title;

    return (
      <Link key={key} href={url}>
        <StyledGridChild>
          <ImageAspect>
            <Image
              src={image?.responsiveImage?.src || ''}
              alt={image?.alt || ''}
              sizes={sizes}
              blurDataURL={image?.responsiveImage?.base64 || ''}
              placeholder={image?.responsiveImage?.base64 ? 'blur' : 'empty'}
              fill
            />
          </ImageAspect>
          <Title>{title}</Title>
          <TextButton arrow>{global?.readMoreButtonLabel || ''}</TextButton>
        </StyledGridChild>
      </Link>
    );
  }
  return (
    <StyledPagePlugs
      style={style}
      $spacingTop={spacingTop}
      $spacingBottom={spacingBottom}
      $marginBlockStart={marginBlockStart}
    >
      <Grid
        columnGap="s"
        rowGap={[{ rowGap: 'l' }, { breakpoint: 'mobileMax', rowGap: 'xl' }]}
        columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 2 }]}
      >
        {plugs.map(plugMapper)}
      </Grid>
    </StyledPagePlugs>
  );
};
