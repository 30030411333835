import styled from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

export const ContactInformationStyled = styled.div`
  margin-bottom: ${spacing.m};
  padding: 0;

  p,
  span,
  a {
    color: ${colors.systemBlack};
    font-size: ${fontSizes.paragraph16};
    font-style: normal;
    line-height: ${lineHeights.paragraph16};
    margin: 0;
    text-decoration: none;

    svg {
      margin-right: ${spacing.xs};
    }
  }

  a {
    display: inline-block;

    &:hover {
      color: ${colors.systemActive};
    }
  }

  .TextBlock {
    margin-bottom: ${spacing.m};

    .Title {
      margin-bottom: ${spacing.xs};
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .Disclaimer {
    margin-top: ${spacing.m};
  }
`;
