import Link from 'next/link';

import { ButtonVariant, StyledButton } from './button.styled';

interface ButtonProps {
  to?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;

  loading?: boolean;
  variant?: ButtonVariant;
  center?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  loading,
  variant = 'primary',
  to,
  onClick,
  children,
  style,
  className,
  center,
}) => {
  if (!to) {
    return (
      <StyledButton
        className={className}
        style={style}
        onClick={onClick}
        $variant={variant}
        $loading={loading}
        $center={center}
      >
        {children}
      </StyledButton>
    );
  }

  const rel
    = to.includes('http://') || to.includes('https://') ? 'noopener' : '';
  const target
    = to.includes('http://') || to.includes('https://') ? '_blank' : '_self';

  return (
    <StyledButton
      as={Link}
      href={to}
      rel={rel}
      target={target}
      className={className}
      style={style}
      $variant={variant}
      $loading={loading}
      $center={center}
    >
      {children}
    </StyledButton>
  );
};
