import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

import { HTag } from '../h-tag/h-tag';
import { Markdown } from '../markdown/markdown';
import { Paragraph } from '../paragraph/paragraph';

export const Background = styled.div<{
  $wide?: boolean;
  $titleAtTheTop?: boolean;
}>`
  position: relative;
  ${({ $titleAtTheTop }) =>
    $titleAtTheTop
    && css`
      margin-block-start: 48px;
    `}
  aspect-ratio: ${({ $titleAtTheTop }) => ($titleAtTheTop ? '1 / 1' : '3 / 4')};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    aspect-ratio: 3 / 4;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: ${({ $wide }) => ($wide ? '21 / 9' : '16 / 9')};
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  z-index: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  background-image: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 36.82%,
    rgb(0 0 0 / 40%) 100%
  );
  padding-block: ${spacing.l};
  padding-inline: ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-inline: ${spacing.side} 4%;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${breakpoints.maxPageWidth};
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-left: -${spacing.s};
  margin-top: -${spacing.s};

  & > * {
    margin-left: ${spacing.s};
    margin-top: ${spacing.s};
  }

  &:empty {
    display: none;
  }
`;

export const Inner = styled.div`
  width: 100%;
  max-width: 1440px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    width: 60%;
  }
`;

export const Label = styled(Paragraph).attrs({
  styleType: 'heroLabel',
  color: 'white',
})<{ $noTextShadow?: boolean }>`
  margin-block-end: ${spacing.xs};
  text-shadow: 0 0 4px rgb(0 0 0 / 50%);
`;

export const Title = styled(HTag).attrs({
  color: 'white',
  styleType: [
    { styleType: 'header2' },
    { breakpoint: 'mobileMax', styleType: 'mega1' },
  ],
  type: 'h1',
})<{ $titleAtTheTop?: boolean }>`
  margin-block-end: ${spacing.s};
  text-shadow: 0 0 10px rgb(0 0 0 / 50%);

  ${({ $titleAtTheTop }) =>
    $titleAtTheTop
    && css`
      text-shadow: none;
      margin-block: 24px;
      margin-inline: 16px;
      padding-top: 24px;
      color: ${colors.wibeDarkGrey};
      font-size: ${fontSizes.header2};
      font-style: normal;
      font-weight: ${fontWeights.light};
      line-height: ${lineHeights.header2};
      text-align: left;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.header1};
        line-height: ${lineHeights.header1};
      }
    `}
`;

export const Intro = styled(HTag).attrs({
  color: 'white',
  styleType: 'heroIngress',
  type: 'h2',
})`
  margin-block-end: ${spacing.m};
  text-shadow: 0 0 8px rgb(0 0 0 / 50%);
  max-inline-size: 1000px;
`;

export const TextContent = styled(Markdown)`
  max-inline-size: 800px;
  color: ${colors.white};
  margin-bottom: ${spacing.m};
  margin-inline: 0;
  font-weight: ${fontWeights.medium};
`;
