import styled from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/wibe/helpers';

export const DownloadFileBoxStyled = styled.span`
  a {
    background-color: ${colors.white};
    border: thin solid ${colors.grey3};
    display: flex;
    flex-direction: row;
    min-height: 64px;
    margin-bottom: ${spacing.xs};
    max-width: 657px;
    padding: ${spacing.s};
    position: relative;
    text-decoration: none;
    width: 100%;

    :hover {
      background-color: ${colors.grey1};
      cursor: pointer;
    }
  }

  .Icon {
    fill: ${colors.wibeDarkGrey};
    position: absolute;

    &:first-of-type {
      left: ${spacing.xs};
      top: ${spacing.s};
    }

    &:last-of-type {
      right: ${spacing.xs};
      top: ${spacing.s};
    }
  }

  .TextWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    text-align: left;
    width: 100%;
  }

  p {
    &.Label {
      color: ${colors.wibeDarkGrey};
      font-size: ${fontSizes.paragraph16};
      font-style: normal;
      font-weight: ${fontWeights.medium};
      line-height: ${fontSizes.paragraph16};
      margin: 0;

      /* hyphens: auto; */
    }

    &.Size {
      color: ${colors.grey5};
      font-size: ${fontSizes.paragraph10};
      margin: 0;
    }
  }
`;
