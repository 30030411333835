import styled from 'styled-components';

import {
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/wibe/helpers';

interface StyledH1Props {
  $center?: boolean;
  $border?: boolean;
  $bold?: boolean;
}

export const StyledH1 = styled.h1<StyledH1Props>`
  border-bottom: ${({ $border }) => ($border ? '1px solid #000' : 'none')};
  font-size: ${fontSizes.header1};
  font-weight: ${({ $bold }) =>
    $bold ? fontWeights.extraBold : fontWeights.medium};
  line-height: ${lineHeights.header1};
  text-align: ${({ $center }) => ($center ? 'center' : 'initial')};
`;
