import { FILTER_TYPE, getFilterSummary } from '@hultafors/wibe/api';

import { Button } from '../button/button';
import { Icon } from '../icon/icon';

import { StyledFilterList } from './selected-filter-list.styled';

interface SelectedFilterListProps {
  filters: any[];
  selectedFilters: any[];
  filterChange(a: any, b: any): void;
}

export const SelectedFilterList: React.FC<SelectedFilterListProps> = ({
  selectedFilters,
  filters,
  filterChange,
}) => {
  /**
   * @desc Construct label from min and max value of parents children together with unit of children
   * @param {object} parent
   * @returns {string}
   */
  const renderNumericFilterLabel = (parent: any) => {
    const currentParent = selectedFilters.find((x) => x.id === parent.id);
    if (currentParent) {
      const summary = getFilterSummary(currentParent);
      return `${summary.min}-${summary.max} ${summary.unit}`;
    }
    return '';
  };

  return filters.length
    ? (
      <StyledFilterList>
        <>
          {selectedFilters.map((parent) => {
            if (parent.type === FILTER_TYPE.NUMERIC) {
              return (
                <Button
                  onClick={() => filterChange(parent, [])}
                  variant="small"
                  key={`selected-filter-${parent.id}`}
                >
                  {renderNumericFilterLabel(parent)}
                  <Icon svg="cross-circle" />
                </Button>
              );
            } else {
              return parent.children.map((child: any) => {
                return (
                  <Button
                    onClick={() => filterChange(parent, child)}
                    variant="small"
                    key={`selected-filter-${child.id}`}
                  >
                    {child.name}
                    <Icon svg="cross-circle" />
                  </Button>
                );
              });
            }
          })}
        </>
      </StyledFilterList>
      )
    : null;
};
