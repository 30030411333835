import styled from 'styled-components';

import {
  colors,
  fontSizes,
  radii,
  spacing,
} from '@hultafors/wibe/helpers';

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  label {
    flex-basis: 100%;
    font-size: ${fontSizes.paragraph12};
    margin-bottom: ${spacing.s};
  }
`;

export const StyledSelect = styled.select`
  appearance: none;
  background: url('/assets/icons/chevronDown.svg');
  background-position: calc(100% - ${spacing.xs}) center;
  background-repeat: no-repeat;
  background-size: ${spacing.m};
  border: thin solid ${colors.grey3};
  border-radius: ${radii.light};
  box-sizing: border-box;
  flex: 1;
  padding: ${spacing.xs};
  padding: ${spacing.s};
  width: 100%;

  &:focus {
    background-color: ${colors.grey1};
    border: thin solid ${colors.grey4};
    outline: none;
  }

  option {
    line-height: 1.4em !important;
    padding: ${spacing.s} ${spacing.xs};

    &:disabled {
      color: ${colors.grey2};
    }
  }

  .ArrowIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 8px;
  }
`;
