import Link from 'next/link';

import {
  createMenuItemUrl,
  DATO_MENU_TYPES,
} from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';

import { Icon } from '../icon/icon';
import { Direction } from '../menu/menu';
import { Paragraph } from '../paragraph/paragraph';

import {
  MenuButton,
  MenuItem,
  MenuLevelBackButton,
  MenuLevelBody,
  MenuLevelCategoryLabel,
  MenuLevelCategoryWrapper,
  MenuLevelHeader,
  StyledFlex,
  StyledMenuLevel,
} from './menu-level.styled';

const hasChildren = (menuItem: any) => {
  return 'children' in menuItem && (menuItem.children || []).length > 0;
};

interface MenuLevelProps {
  menuState: any;
  menuItem?: any;
  toggleMenu(): void;
  toggleSubMenu(menuItem: any, direction: Direction): void;
  documentsPart?: any[];
}

export const MenuLevel: React.FC<MenuLevelProps> = ({
  menuState,
  menuItem = {},
  toggleMenu,
  toggleSubMenu,
  documentsPart,
}) => {
  const { global } = useGlobal();
  if (!hasChildren(menuItem)) {
    return null;
  }

  const indexOfCurrentMenuItem = menuState.items.findIndex(
    (menuStateItem: any) => menuStateItem.id === menuItem.id,
  );

  const className
    = menuState.items.length > 0
    && indexOfCurrentMenuItem === menuState.items.length - 1
      ? undefined
      : indexOfCurrentMenuItem < 0
        ? 'Hide-Right'
        : 'Hide-Left';

  return (
    <StyledMenuLevel
      key={`menu-level.${menuItem.id}.${menuItem.title}`}
      className={[className].join(' ')}
    >
      <MenuLevelHeader>
        <MenuLevelBackButton
          onClick={() => toggleSubMenu(menuItem, 'backward')}
          aria-label={global?.previous || ''}
        >
          <Icon svg="large-arrow-left" />
        </MenuLevelBackButton>
        <MenuLevelBackButton
          onClick={toggleMenu}
          aria-label={global?.close || ''}
        >
          <Icon svg="cross-24" />
        </MenuLevelBackButton>
      </MenuLevelHeader>
      <MenuLevelBody>
        <StyledFlex>
          {(menuItem.children || []).map((childMenuItem: any) => {
            // Treat products category links as page links
            const isGuides = menuItem.menuType === DATO_MENU_TYPES.GUIDES;
            const useParentCategoryLinks = !!childMenuItem.page;
            let url = undefined;

            if (isGuides) {
              return (
                <MenuLevelCategoryWrapper
                  key={`menu-level-item.${childMenuItem.id}.${childMenuItem.title}`}
                >
                  <MenuLevelCategoryLabel>
                    {childMenuItem.title}
                  </MenuLevelCategoryLabel>
                  {(childMenuItem.children || []).map((subChild: any) => {
                    const url = createMenuItemUrl({
                      parent: childMenuItem,
                      child: subChild,
                      useParentCategoryLinks,
                    });

                    // Seems like there should always be a URL, but just in case there is not...
                    return url
                      ? (
                        <MenuItem
                          key={`menu-level-child.${subChild.id}.${subChild.title}`}
                        >
                          <Link href={url}>
                            <Paragraph styleType="paragraph14">
                              {subChild.title}
                            </Paragraph>
                          </Link>
                        </MenuItem>
                        )
                      : null;
                  })}
                </MenuLevelCategoryWrapper>
              );
            }

            // TODO: double-check that part, not sure when it should use "parent" or "child" or "useParentCategoryLinks" atm
            url
              = menuItem.depth > 0
                ? createMenuItemUrl({
                  parent: menuItem,
                  child: childMenuItem,
                  useParentCategoryLinks,
                })
                : createMenuItemUrl({ parent: childMenuItem });

            return hasChildren(childMenuItem)
              ? (
                <MenuItem
                  $margin
                  key={`menu-level-item.${childMenuItem.id}.${childMenuItem.title}`}
                >
                  <Link href={url}>
                    <Paragraph as="span" styleType="paragraph16Medium">
                      {childMenuItem.title}
                    </Paragraph>
                  </Link>
                  <MenuButton
                    onClick={() => toggleSubMenu(childMenuItem, 'forward')}
                    $borderLess
                    $stretch={false}
                    aria-label={global?.next || ''}
                  >
                    <Icon svg="large-arrow-right" />
                  </MenuButton>
                </MenuItem>
                )
              : (
                <MenuItem
                  onClick={toggleMenu}
                  $margin
                  key={`menu-level-item.${childMenuItem.id}.${childMenuItem.title}`}
                >
                  <Link href={url}>
                    <Paragraph as="span" styleType="paragraph16">
                      {childMenuItem.title}
                    </Paragraph>
                  </Link>
                </MenuItem>
                );
          })}

          {menuItem.menuType === DATO_MENU_TYPES.GUIDES
          && documentsPart?.map((item, i) => (
            <MenuLevelCategoryLabel key={`Documents-Link-${i}`}>
              <MenuItem>
                <Link href={item.url}>
                  <Paragraph styleType="paragraph14">{item.title}</Paragraph>
                </Link>
              </MenuItem>
            </MenuLevelCategoryLabel>
          ))}
        </StyledFlex>
      </MenuLevelBody>
    </StyledMenuLevel>
  );
};
