import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

export const StyledSearchResultIntro = styled(Paragraph).attrs({
  styleType: 'paragraph14',
})`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};

  h1 + & {
    margin-block-start: ${spacing.m};
  }

  margin-block-end: ${spacing.l};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-end: ${spacing.l};
  }
`;

export const Label = styled.span`
  display: block;
`;

export const Term = styled(HTag).attrs({
  type: 'span',
  styleType: 'header3',
})`
  display: block;
`;
