import { useMemo } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { KeyLoader } from 'swr';
import useSWRInfinite from 'swr/infinite';

import { breakpoints } from '@hultafors/shared/constants';
import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import { RELATED_PRODUCTS_PAGE_SIZE } from '@hultafors/wibe/api';
import { WibeProduct } from '@hultafors/wibe/types';

import { Pager } from '../pager/pager';
import { Product } from '../product/product';

import {
  Intro,
  ProductsGrid,
  RelatedProductsWithTabsStyled,
  SkeletonWrapper,
} from './related-products-with-tabs.styled';
const Skeleton = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Skeleton),
);

interface RelatedProductsWithTabsProps {
  safetyEquipmentLabel?: string;
  header?: string;
  intro?: string;
  isLoading?: boolean;
  numberOfItems?: number;

  ofText?: string;
  showMoreText?: string;
  productsText?: string;
  style?: React.CSSProperties;

  safetyEquipmentUrl?: string;
  productId?: string;
}

export const RelatedProductsWithTabs: React.FC<
  RelatedProductsWithTabsProps
> = ({ intro, style, safetyEquipmentUrl, productId, ...props }) => {
  const { locale } = useRouter();

  // Creates api path that also acts as cache key for swr
  const getKey: KeyLoader = (
    index: number,
    previousPageData: ParsedProductsApiResponse<WibeProduct> | null,
  ) => {
    if (
      !safetyEquipmentUrl
      || !productId
      || (previousPageData
      && previousPageData.paging.pageCount
      === previousPageData.paging.pageNumber)
    ) {
      return null;
    }

    const params = new URLSearchParams({
      productId,
      pageSize: `${RELATED_PRODUCTS_PAGE_SIZE}`,
      pageNumber: `${index + 1}`,
      ...(locale && locale !== 'com' ? { locale } : {}),
    });

    return `${safetyEquipmentUrl}?${params.toString()}`;
  };

  // Initialize swr for product data fetching
  const { data, error, isLoading, size, setSize }
    = useSWRInfinite<ParsedProductsApiResponse<WibeProduct>>(getKey);

  const items = useMemo<WibeProduct[]>(() => {
    return data?.flatMap((page) => page?.items || []) || [];
  }, [data]);

  const paging = useMemo(() => {
    const length = data?.length || 0;
    return data?.[length - 1]?.paging;
  }, [data]);

  const loadMore = () => {
    setSize(size + 1);
  };

  // const { data, isLoading, isLoading, error } = useSWR<
  //   ParsedProductsApiResponse<WibeladdersProduct>,
  //   Error
  // >(
  //   safetyEquipmentUrl && productId
  //     ? `${safetyEquipmentUrl}?${new URLSearchParams({
  //         productId,
  //         pageSize: '2',
  //       }).toString()}`
  //     : null
  // );
  // const [activeTab, setActiveTab] = useState(1);

  // const shouldRenderTabs = () => {
  //   return /*haveSpareParts() &&*/ haveaccessories();
  // };

  // const renderTabs = () => {
  //   return shouldRenderTabs() ? (
  //     <div className="Tabs">
  //       {haveaccessories() && (
  //         <TextButton clean tab tabActive={1 === activeTab} onClick={() => setActiveTab(1)}>
  //           {safetyEquipmentLabel}
  //         </TextButton>
  //       )}
  //       {/* {haveSpareParts() && (
  //         <TextButton clean tab tabActive={2 === activeTab} onClick={() => setActiveTab(2)}>
  //           {props.sparePartsLabel}
  //         </TextButton>
  //       )} */}
  //     </div>
  //   ) : null;
  // };

  // const haveSpareParts = () => {
  //   return props.spareParts && props.spareParts.length > 0;
  // };
  // const renderSpareParts = () => {
  //   return props.spareParts.map((product, i) => (
  //     <Product
  //       key={`spare-part-item-${i}`}
  //       product={product}
  //       className="Product"
  //       routerState={props.routerState}
  //       noMargin
  //     />
  //   ));
  // };

  const sizes = [
    '(min-width: 1504px) 382px',
    `(min-width: ${breakpoints.desktop}) calc((100vw - 358px) / 3 )`,
    `(min-width: ${breakpoints.mobileMax}) calc((100vw - 244px) / 2 )`,
    'calc(100vw - 98px)',
  ].join(', ');

  const productMapper = (product: WibeProduct) => (
    <Product
      key={`safety-equipment-item-${product.productId}`}
      product={product}
      sizes={sizes}
      noMargin
    />
  );

  const skeletonMapper = (_value: number, index: number) => {
    const key = `ModelSkeleton-${index}`;
    return (
      <SkeletonWrapper key={key}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </SkeletonWrapper>
    );
  };

  return safetyEquipmentUrl && productId
    ? (
      <RelatedProductsWithTabsStyled style={style}>
        <Intro>{intro}</Intro>
        <ProductsGrid>
          {isLoading
            ? new Array(RELATED_PRODUCTS_PAGE_SIZE).fill(1)?.map(skeletonMapper)
            : items?.map(productMapper)}
          {isLoading
          && items?.length
          && new Array(size * RELATED_PRODUCTS_PAGE_SIZE - items.length)
            .fill(1)
            ?.map(skeletonMapper)}
        </ProductsGrid>
        {paging && (
          <Pager
            showMoreText={props.showMoreText || ''}
            ofText={props.ofText}
            productsText={props.productsText}
            loadMore={loadMore}
            pagerData={paging}
            loading={isLoading}
            itemCount={RELATED_PRODUCTS_PAGE_SIZE}
          />
        )}
      </RelatedProductsWithTabsStyled>
      )
    : null;
};
