import { StyledIconButton } from './icon-button.styled';

interface IconButtonWrapperProps {
  'children'?: React.ReactNode;
  'onClick'?: React.MouseEventHandler<HTMLButtonElement>;
  'className'?: string;
  'name'?: string;
  'type'?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  'aria-label'?: string;
  'disabled'?: boolean;
}

export const IconButton: React.FC<IconButtonWrapperProps> = ({
  name,
  className,
  children,
  onClick,
  type = 'button',
  disabled,
  ...props
}) => (
  <StyledIconButton
    name={name}
    aria-label={props['aria-label'] || name}
    className={className}
    onClick={onClick}
    type={type}
    disabled={disabled}
  >
    {children}
  </StyledIconButton>
);
