import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

import { Button } from '../button/button';
import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

export const StyledProductDetailData = styled.div`
  margin-block: ${spacing.m};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    margin-block: 0 ${spacing.xs};
  }

  .ArticleNumber {
    color: ${colors.grey1};
    margin: 0;
    margin-top: ${spacing.xs};
  }

  .header {
    margin-bottom: ${spacing.xs};
  }
`;

export const PriceWrapper = styled.div`
  align-items: baseline;
  display: flex;
  gap: ${spacing.xs};
  margin-bottom: ${spacing.m};
`;

export const Price = styled(Paragraph).attrs({
  styleType: 'paragraph14',
})`
  margin: 0;

  &:empty {
    display: none;
  }
`;

export const RrpLabel = styled(Paragraph).attrs({
  styleType: 'paragraph10',
  color: 'grey4',
})`
  margin: 0;

  &:empty {
    display: none;
  }
`;

export const InfoFirstBlock = styled.div`
  position: relative;

  .NewLabel {
    color: ${colors.hellSalmon};
    margin-bottom: ${spacing.xs};

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      position: absolute;
      top: -${spacing.side};
    }
  }
`;

export const Name = styled(HTag).attrs({
  type: 'h1',
  styleType: 'header3',
  className: 'header',
  color: 'wibeDarkGrey',
})``;

export const ModelsButtonWrapper = styled.div`
  display: flex;
  gap: ${spacing.s};
  margin-bottom: ${spacing.s};
`;

export const ModelsButton = styled(Button).attrs({
  variant: 'primary-dark',
})`
  flex: 1;
  text-align: center;

  svg {
    margin-inline: 0 ${spacing.xs};
  }
`;

export const FeatureList = styled.ul`
  padding: 0;
  padding-inline-start: calc(${spacing.s} * 0.3);
  margin-block-end: ${spacing.m};

  &:empty {
    display: none;
  }

  > * + * {
    margin-block-start: ${spacing.xs};
  }
`;
export const FeatureItem = styled.li`
  color: ${colors.systemBlack};
  font-size: ${fontSizes.paragraph16};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeights.paragraph16};
  padding-inline-start: calc(${spacing.s} * 0.7);

  &::marker {
    content: '\2022';
  }
`;
export const VideoPlayerLink = styled.a`
  align-items: center;
  display: inline-flex;
  margin-bottom: ${spacing.m};

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: ${spacing.xs};
  }
`;
