import styled, { css } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

interface MobileImageProps {
  $breakpointMax?: Breakpoint;
}

interface TabletImageProps {
  $breakpointMin?: Breakpoint;
  $breakpointMax?: Breakpoint;
}

interface DesktopImageProps {
  $breakpointMin?: Breakpoint;
  $force?: boolean;
}

function breakpointMin({
  $breakpointMin,
}: TabletImageProps | DesktopImageProps) {
  if ($breakpointMin) {
    return breakpoints[$breakpointMin];
  }
  return '';
}

function breakpointMax({
  $breakpointMax,
}: MobileImageProps | TabletImageProps) {
  if ($breakpointMax) {
    return breakpoints[$breakpointMax];
  }
  return '';
}

const sharedImageStyles = css`
  position: relative;
  block-size: 100%;
  inline-size: 100%;
`;

export const MobileImage = styled.div<MobileImageProps>`
  ${sharedImageStyles};

  @media screen and (min-width: ${breakpointMax}) {
    display: none;
  }
`;
export const TabletImage = styled.div<TabletImageProps>`
   ${sharedImageStyles};
  display: none;

  @media screen and (min-width: ${breakpointMin}) {
    display: block;
  }

  @media screen and (min-width: ${breakpointMax}) {
    display: none;
  }
`;
export const DesktopImage = styled.div<DesktopImageProps>`
    ${sharedImageStyles};
  ${({ $force, $breakpointMin }) =>
    !$force
    && $breakpointMin
    && css`
      display: none;

      @media screen and (min-width: ${breakpoints[$breakpointMin]}) {
        display: block;
      }
    `}
`;
