import { Spacing } from '@hultafors/wibe/helpers';

import { StyledDivider } from './divider.styled';

interface DividerProps {
  margin?: Spacing;
}

export const Divider: React.FC<DividerProps> = ({ margin = 'm' }) => {
  return <StyledDivider $margin={margin} />;
};
