import styled from 'styled-components';

import { colors, fontWeights, spacing } from '@hultafors/wibe/helpers';

export const StyledFilter = styled.div`
  .DropDownFilters {
    display: flex;
    flex-direction: column;
  }

  .Accordion > div {
    border-bottom: 1px solid ${colors.grey3};
    position: relative;

    .FilterType {
      padding-bottom: ${spacing.s};
    }

    button {
      margin-right: ${spacing.m};
      padding: 0;

      p {
        font-weight: ${fontWeights.medium};
      }

      &.ChildSelected {
        span {
          position: relative;
        }

        span::before {
          background-color: #000;
          border-radius: 50%;
          bottom: -10px;
          content: '';
          display: block;
          height: 4px;
          left: 50%;
          position: absolute;
          width: 4px;
        }
      }
    }

    .CollapseContent.isOpen {
      padding-bottom: 20px;
    }

    .SubFilters {
      background-color: ${colors.white};
      min-width: 220px;
      top: 36px;
      z-index: 1;
    }
  }
`;
