import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing, Spacing } from '@hultafors/wibe/helpers';

import { GridChild } from '../grid-child/grid-child';
import { HTag } from '../h-tag/h-tag';

interface StyledPagePlugsProps {
  $marginBlockStart?: Spacing;
  $spacingTop?: boolean;
  $spacingBottom?: boolean;
}

export const StyledPagePlugs = styled.section<StyledPagePlugsProps>`
  margin-inline: ${spacing.s};
  margin-block: 0;

  ${({ $marginBlockStart }) =>
    $marginBlockStart
    && css`
      margin-top: ${spacing[$marginBlockStart]};
    `}
  ${({ $spacingBottom }) =>
    $spacingBottom
    && css`
      margin-block-end: ${spacing.xl};
    `}
    ${({ $spacingTop }) =>
    $spacingTop
    && css`
      margin-block-start: ${spacing.xl};
    `}
  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-inline: ${spacing.side};
  }
`;

export const StyledGridChild = styled(GridChild)`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-bottom: 0;
  }
`;

export const Title = styled(HTag).attrs({
  type: 'h4',
  styleType: 'header4',
})`
  margin: ${spacing.s} 0 ${spacing.xs};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.m} 0 ${spacing.s};
  }
`;

export const ImageAspect = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
`;

export const StyledImage = styled.img`
  height: 100%;
  inset: 0;
  object-fit: cover;
  position: absolute;
  width: 100%;
`;

export const DocumentImage = styled.div`
  background-color: ${colors.grey1};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: thin solid ${colors.grey2};
  color: ${colors.grey3};

  svg {
    width: 50%;
    height: 50%;
  }
`;
