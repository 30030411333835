import { CSSProperties } from 'styled-components';

import { AlertPlacement, StyledAlert } from './alert.styled';

export const alertStatuses = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

export type AlertStatus = keyof typeof alertStatuses;

export const alertSizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export type AlertSize = keyof typeof alertSizes;

interface AlertProps {
  size: AlertSize;
  status: AlertStatus;
  position: CSSProperties['position'];
  placement: AlertPlacement;
}

export const Alert: React.FC<AlertProps> = ({
  size = 'medium',
  status = 'info',
  position = 'relative',
  placement = 'bottom',
}) => (
  <StyledAlert
    $status={status}
    $size={size}
    $position={position}
    $placement={placement}
  />
);
