import Link from 'next/link';

import { Icon } from '../icon/icon';

import { TextButtonStyled, TextButtonVariant } from './text-button.styled';

export const TextButtonIcon: React.FC<IconProps> = ({ icon = null, style }) => {
  return typeof icon === 'string'
    ? (
      <img src={icon} alt="icon" style={style} />
      )
    : (
      <>{icon}</>
      );
};

interface IconProps {
  icon?: React.ReactNode;
  style?: React.CSSProperties;
}

interface TextButtonProps extends IconProps {
  loading?: boolean;
  to?: string;
  onClick?: React.MouseEventHandler;
  children?: React.ReactNode;
  className?: string;
  tab?: boolean;
  tabActive?: boolean;
  small?: boolean;
  leftIcon?: boolean;
  arrow?: boolean;
  space?: boolean;
  clean?: boolean;

  variant?: TextButtonVariant;
  iconSize?: number;
  icon?: React.ReactNode;
}

export const TextButton: React.FC<TextButtonProps> = ({
  arrow,
  loading,
  variant = 'black',
  to,
  icon,
  space,
  iconSize = 24,
  onClick,
  clean,
  tab,
  tabActive,
  leftIcon,
  style,
  className,
  children,
  small,
}) => {
  if (!to && !onClick) {
    return null;
  }

  const as = ({ children, className, to, onClick }: TextButtonProps) =>
    (typeof to === 'string' && (
      <Link
        className={className}
        href={to}
        onClick={onClick}
        style={style}
        {...(to.startsWith('http://') || to.startsWith('https://')
          ? { rel: 'noreferrer noopener', target: '_blank' }
          : {})}
      >
        {children}
      </Link>
    ))
    || null;

  if (!to) {
    return (
      <TextButtonStyled
        className={className}
        style={style}
        onClick={onClick}
        $variant={variant}
        $arrow={arrow}
        $icon={icon}
        $space={space}
        $loading={loading}
        $iconSize={iconSize}
        $clean={clean}
        $tab={tab}
        $tabActive={tabActive}
        $leftIcon={leftIcon}
        $small={small}
      >
        {icon && !arrow && leftIcon && <TextButtonIcon icon={icon} />}
        {arrow && !icon && leftIcon && <Icon svg="large-arrow-left" />}
        {children}
        {icon && !arrow && !leftIcon && <TextButtonIcon icon={icon} />}
        {arrow && !icon && !leftIcon && <Icon svg="large-arrow-right" />}
      </TextButtonStyled>
    );
  }
  return (
    <TextButtonStyled
      as={as}
      className={className}
      to={to}
      style={style}
      $variant={variant}
      $loading={loading}
      $icon={icon}
      $iconSize={iconSize}
      $arrow={!icon && arrow}
      $clean={clean}
      $tab={tab}
      $tabActive={tabActive}
      $leftIcon={leftIcon}
      $small={small}
    >
      {icon && !arrow && leftIcon && (
        <TextButtonIcon
          icon={icon}
          style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
        />
      )}
      {arrow && !icon && leftIcon && (
        <TextButtonIcon
          icon="/assets/icons/largeArrowLeft.svg"
          style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
        />
      )}
      {children}
      {icon && !arrow && !leftIcon && (
        <TextButtonIcon
          icon={icon}
          style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
        />
      )}
      {arrow && !icon && !leftIcon && (
        <TextButtonIcon
          icon="/assets/icons/largeArrowRight.svg"
          style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
        />
      )}
    </TextButtonStyled>
  );
};
