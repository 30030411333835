import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  clampedContainer,
  colors,
  spacing,
} from '@hultafors/wibe/helpers';

export const ProductDetailsStyled = styled.section`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  gap: ${spacing.m};
  margin-block-start: ${spacing.xs};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    ${clampedContainer('desktopMediumLarge')};
    margin-block: ${spacing.l} ${spacing.xl};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    gap: ${spacing.s};
    grid-template-columns: repeat(2, 1fr);
  }

  > * {
    min-width: 0;
  }

  @media screen and (min-width: ${breakpoints.desktopMediumLarge}) {
    /* gap: 0; */
    > *:nth-child(2) {
      /* padding-inline: 120px; */
    }
  }
`;

export const ProductDetailsInner = styled.div`
  border-top: thin solid ${colors.grey2};
  padding-inline: ${spacing.side};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    border-top: none;
    padding-inline: 0;
  }

  @media screen and (min-width: ${breakpoints.desktopMediumLarge}) {
    /* max-width: 60%; */
    padding-inline: 17%;

    /* margin-inline: auto; */
  }
`;
/*
434, 6667
660
225,333333333333333
112,666666666666667
66%
*/
