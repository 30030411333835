import { Fragment, useEffect, useRef, useState } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';
import { useKey } from 'react-use';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import {
  createMenuItemUrl,
  DATO_MENU_TYPES,
} from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';
import {
  MenuItemFragment,
  SecondaryMenuItemFragment,
} from '@hultafors/wibe/types';

import { AutoCompleteSearchBox } from '../auto-complete-search-box/auto-complete-search-box';
import { Favorites } from '../favorites/favorites';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { Icon } from '../icon/icon';
import { Menu } from '../menu/menu';
import { MenuPlugs } from '../menu-plugs/menu-plugs';

import {
  Arrow,
  Backdrop,
  Container,
  ExtraNav,
  Flex,
  GuidesExtraLinks,
  IconLabel,
  InnerDrop,
  Logo,
  MenuButton,
  MenuDrop,
  MenuItem,
  NavIconButton,
  NavStyled,
  PartnerLink,
  SearchWrap,
  SiteLogoWrapper,
  SubMenuHeader,
  SubMenuItem,
  TransformWrapper,
} from './nav.styled';

export interface OpenSettings {
  favorites: boolean;
  search: boolean;
  mobileMenu: boolean;
}

export type NavMenuType = keyof OpenSettings;

const defaultOpenSettings: OpenSettings = {
  favorites: false,
  search: false,
  mobileMenu: false,
};

export const Nav: React.FC = () => {
  const { menu, allMenuItems, global } = useGlobal();

  const router = useRouter();

  const [subMenuShowing, setSubMenuShowing] = useState<string>('');
  const [isOpen, setIsOpen] = useState<OpenSettings>(defaultOpenSettings);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);

  const menuWrapperRef = useRef<HTMLDivElement>(null);
  const prevSubMenuOpenedRef = useRef<string>('');
  const { toggle } = useMarketPicker();

  useEffect(() => {
    setSubMenuShowing('');
    setIsOpen(defaultOpenSettings);
  }, [router.asPath]);

  useEffect(() => {
    if (isOpen['search']) {
      searchRef?.current?.focus();
    }

    if (!isOpen['search']) {
      setSearchActive(false);
    }
  }, [isOpen]);

  const toggleMenu = (key: NavMenuType) => {
    const open = !isOpen[key];

    setSubMenuShowing('');
    setIsOpen({ ...defaultOpenSettings, [key]: open });
  };

  useKey('Escape', () => {
    setSubMenuShowing('');
  });

  useEffect(() => {
    prevSubMenuOpenedRef.current = subMenuShowing;
  });

  const getSecondaryMenu = (
    isProductMenu: boolean,
  ): SecondaryMenuItemFragment[] => {
    return isProductMenu
      ? menu?.secondaryProductMenu || []
      : menu?.secondaryMenu || [];
  };

  const allMenuItemsMapper = (menuItem: any) => {
    // Treat products category links as page links
    const isProducts = menuItem.menuType === DATO_MENU_TYPES.PRODUCTS;
    // That flag seems perhaps redundant to "isProducts" at the moment
    // but not sure about all possible scenarios based on CMS data type
    const useParentCategoryLinks = !!menuItem.children[0]?.page;

    const menuItemMapper = (child: any, index: number) => {
      return (
        <GridChild
          key={`child-${index}`}
          columnSpan={[{ columns: 1, start: (index % 4) + 1 }]}
        >
          <InnerDrop>
            {useParentCategoryLinks
              ? (
                <SubMenuItem
                  href={createMenuItemUrl({ parent: child })}
                  $products={isProducts}
                >
                  {child.title}
                  {isProducts && (
                    <Arrow>
                      <Icon svg="large-arrow-right" />
                    </Arrow>
                  )}
                </SubMenuItem>
                )
              : (
                <SubMenuHeader>{child.title}</SubMenuHeader>
                )}
            {child.children.map((subChild: any) => {
              // ugly hack to hide some sub nodes for some markets
              if (subChild.hideThisNode) {
                return null;
              }

              return (
                <SubMenuItem
                  key={`sub-child-item.${subChild.id}.${subChild.title}`}
                  href={createMenuItemUrl({
                    parent: child,
                    child: subChild,
                    useParentCategoryLinks,
                  })}
                >
                  {subChild.title}
                </SubMenuItem>
              );
            })}
          </InnerDrop>
        </GridChild>
      );
    };

    const secondaryMapper = (secondMenuItem: any, index: number) => {
      return (
        <SubMenuItem
          key={`slask-item-${index}`}
          href={secondMenuItem.url}
          $products={true}
        >
          {secondMenuItem.title}
        </SubMenuItem>
      );
    };

    // Returns secondary menu only if menuType is NOT 'products'.
    // This menu should only show in the 'Guides' column.
    function shouldReturnExtraLinks(menuType: string) {
      if (menuType === 'products') {
        return null;
      }
      return (
        <GuidesExtraLinks>
          {getSecondaryMenu(isProducts).map(secondaryMapper)}
        </GuidesExtraLinks>
      );
    }

    return (
      menuItem?.children.length > 0 && (
        <Fragment key={`menudrop-submenu.${menuItem.id}`}>
          <Backdrop
            className={`${subMenuShowing === menuItem.title ? 'show' : ''}`}
          />
          <MenuDrop
            $showSubMenu={subMenuShowing === menuItem.title ? true : false}
            $closeFast={
              prevSubMenuOpenedRef.current && subMenuShowing ? true : false
            }
          >
            <Grid columns={4} columnGap={[{ columnGap: 0 }]}>
              <GridChild columnSpan={[{ columns: 4, start: 1 }]}>
                <Grid columns={4} rowGap="l">
                  {menuItem?.children.map(menuItemMapper)}
                  {shouldReturnExtraLinks(menuItem?.menuType)}
                </Grid>
              </GridChild>
            </Grid>
            <Grid columns={4} columnGap={[{ columnGap: 0 }]}>
              {isProducts && (
                <GridChild columnSpan={[{ columns: 4, start: 1 }]}>
                  <MenuPlugs plugs={getSecondaryMenu(isProducts)} />
                </GridChild>
              )}
            </Grid>
          </MenuDrop>
        </Fragment>
      )
    );
  };

  const desktopMenuMapper = ({
    id,
    url = '',
    title = '',
    children,
  }: MenuItemFragment) => {
    const key = `menu-item-${id}`;

    if (children?.length) {
      return (
        <MenuButton
          key={key}
          $hasChildrenStyled
          $menuOpen={
            subMenuShowing && title && subMenuShowing === title ? true : false
          }
          onClick={() => {
            setIsOpen(defaultOpenSettings);
            setSubMenuShowing(
              subMenuShowing && title && subMenuShowing === title
                ? ''
                : title || '',
            );
          }}
        >
          {title}
        </MenuButton>
      );
    }
    if (url) {
      return (
        <MenuItem href={url} key={key}>
          {title || url}
        </MenuItem>
      );
    }

    return null;
  };

  if (!allMenuItems.length) {
    return null;
  }

  return (
    <NavStyled>
      <Backdrop $show={searchActive} />
      <TransformWrapper ref={menuWrapperRef}>
        <Container>
          <Flex>
            {global?.siteLogo?.url
            && (
              <Logo href="/" aria-label={global?.siteName || 'W.steps'}>
                <SiteLogoWrapper>
                  <Image
                    src={global.siteLogo.url}
                    alt=""
                    width={98}
                    height={48}
                  />
                </SiteLogoWrapper>
              </Logo>
            )}
            <Flex $desktop>{allMenuItems?.map(desktopMenuMapper)}</Flex>
          </Flex>
          <Flex $right>
            <SearchWrap $hide={!isOpen.search}>
              {isOpen.search && (
                <AutoCompleteSearchBox
                  className="SearchWrapper"
                  isOpen={isOpen.search}
                  focusInput
                  full
                  // searchRef={searchRef}
                  onSearchActive={(isActive: boolean) => {
                    setSearchActive(isActive);
                  }}
                />
              )}
            </SearchWrap>
            <ExtraNav>
              <NavIconButton
                onClick={() => {
                  toggleMenu('search');
                }}
                aria-label={
                  isOpen.search
                    ? global?.close || ''
                    : global?.searchProducts || ''
                }
              >
                <Icon
                  svg={isOpen.search ? 'cross-24' : 'search-24'}
                  width={24}
                  height={24}
                />
                <IconLabel>{global?.searchProducts}</IconLabel>
              </NavIconButton>

              <NavIconButton
                aria-label={global?.changeLanguage || ''}
                onClick={() => toggle(true)}
              >
                <Icon svg="language" />
                <IconLabel>{global?.changeLanguage}</IconLabel>
              </NavIconButton>

              <NavIconButton onClick={() => toggleMenu('favorites')}>
                <Icon svg="bookmark-24" />
                <IconLabel>{global?.favorites}</IconLabel>
              </NavIconButton>
              {global?.urlToPartnerPortal && (
                <PartnerLink
                  href={global?.urlToPartnerPortal || ''}
                  rel="noreferrer noopener"
                  target="_blank"
                  aria-label={global?.partnerPortalLabel || ''}
                >
                  <Icon svg="partner-login-24" />
                  <IconLabel>{global?.partnerPortalLabel}</IconLabel>
                </PartnerLink>
              )}
              <NavIconButton
                onClick={() => toggleMenu('mobileMenu')}
                aria-label={global?.showMenu || ''}
                $mobile
              >
                <Icon svg="hamburger" />
              </NavIconButton>
            </ExtraNav>
          </Flex>
        </Container>
      </TransformWrapper>
      {allMenuItems?.map(allMenuItemsMapper)}
      {isOpen.favorites && (
        <Favorites
          isOpen={isOpen.favorites}
          toggleMenu={() => toggleMenu('favorites')}
          header={global.favorites || ''}
          clearText={global.clearAll || ''}
          badgeText={global.new || ''}
          rrpLabel={global.rrpLabel || ''}
          rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
          favoritesEmpty={global?.noFavorites || ''}
          isSmall
        />
      )}
      {isOpen.mobileMenu && (
        <Menu
          isOpen={isOpen.mobileMenu}
          toggleMenu={() => {
            setSubMenuShowing('');
            toggleMenu('mobileMenu');
          }}
          items={allMenuItems}
          secondaryProductMenu={menu?.secondaryProductMenu}
          documentsPart={getSecondaryMenu(false)}
          partnerPortalLabel={global?.partnerPortalLabel || ''}
          urlToPartnerPortal={global?.urlToPartnerPortal || ''}
        />
      )}
    </NavStyled>
  );
};
