import styled from 'styled-components';

import { colors, spacing } from '@hultafors/wibe/helpers';

export const StyledToggleSwitchContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledToggleSwitch = styled.label`
  display: inline-block;
  height: 32px;
  min-width: 48px;
  position: relative;

  input:checked + .slider {
    background-color: ${colors.systemOk};
  }

  input:checked + .slider::before {
    transform: translateX(17px);
  }
`;

export const StyledInput = styled.input`
  height: 0;
  opacity: 0;
  width: 0;
`;

export const StyledSliderSpan = styled.span`
  background-color: ${colors.grey2};
  border-radius: 16px;
  inset: 0;
  cursor: pointer;
  position: absolute;
  transition: 0.2s;

  ::before {
    background-color: ${colors.white};
    border-radius: 50%;
    bottom: 3px;
    box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
    content: '';
    height: 26px;
    left: 3px;
    position: absolute;
    transition: 0.2s;
    width: 26px;
  }
`;

export const StyledToggleSwitchLabel = styled.label`
  margin-right: ${spacing.s};
`;
