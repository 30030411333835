import { CtaFragment, HeroFragment } from '@hultafors/wibe/types';

import { Button } from '../button/button';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { HeroImage } from '../hero-image/hero-image';

import {
  Background,
  ButtonWrapper,
  ContentWrapper,
  Intro,
  Label,
  TextContent,
  Title,
} from './hero.styled';

interface HeroProps extends Omit<HeroFragment, 'id'> {
  ingress?: string;
  cta?: CtaFragment[];
  priority?: boolean;
}

export const Hero: React.FC<HeroProps> = ({
  cta,
  description,
  firstCtaLink,
  firstCtaText,
  image,
  imageMobile,
  imageTablet,
  ingress,
  label,
  priority,
  secondCtaLink,
  secondCtaText,
  title,
  titleAtTheTop,
  wide,
}) => {
  if (!image && !title) {
    return null;
  }

  // Below function is only used while component is still used outside of content area
  // to ensure smooth transitioning
  function checkIfIsContentArea() {
    if (ingress && cta) {
      return (
        <>
          <Intro>{ingress}</Intro>
          <ButtonWrapper>
            {cta?.[0]?.label && cta[0].url && (
              <Button to={cta[0].url}>{cta[0].label}</Button>
            )}
            {cta?.[1]?.label && cta[1].url && (
              <Button variant="secondary" to={cta[1].url}>
                {cta[1].label}
              </Button>
            )}
          </ButtonWrapper>
        </>
      );
    }
    return (
      <>
        {description && (
          <TextContent
            className="hero-description bold"
            options={{ forceBlock: true }}
          >
            {description}
          </TextContent>
        )}
        <ButtonWrapper>
          {firstCtaText && firstCtaLink && (
            <Button to={firstCtaLink}>{firstCtaText}</Button>
          )}
          {secondCtaText && secondCtaLink && (
            <Button variant="secondary" to={secondCtaLink}>
              {secondCtaLink}
            </Button>
          )}
        </ButtonWrapper>
      </>
    );
  }

  return (
    <>
      {titleAtTheTop && (
        <>
          <Grid columnGap={0}>
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 8, start: 3 },
                { breakpoint: 'desktop', columns: 6, start: 4 },
              ]}
            >
              <Title $titleAtTheTop={titleAtTheTop}>{title}</Title>
              {description && (
                <TextContent
                  className="hero-description bold"
                  options={{ forceBlock: true }}
                >
                  {description}
                </TextContent>
              )}
            </GridChild>
          </Grid>
          <Background $wide={wide} $titleAtTheTop={titleAtTheTop}>
            <HeroImage
              desktop={image}
              tablet={imageTablet}
              mobile={imageMobile}
              priority={priority}
            />
          </Background>
        </>
      )}
      {!titleAtTheTop && (
        <Background $wide={wide}>
          <ContentWrapper>
            {label && <Label>{label}</Label>}
            <Title>{title}</Title>
            {checkIfIsContentArea()}
          </ContentWrapper>
          <HeroImage
            desktop={image}
            tablet={imageTablet}
            mobile={imageMobile}
            priority={priority}
          />
        </Background>
      )}
    </>
  );
};
