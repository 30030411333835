export const lineHeights = {
  mega2: '40px',
  mega1: '58px',
  paragraph10: '14px',
  $paragraph12: '16px',
  paragraph14: '18px',
  paragraph16: '24px',
  header1: '44px',
  header2: '34px',
  header3: '28px',
  header4: '26px',
  header5: '24px',
} as const;

export type LineHeight = keyof typeof lineHeights;
