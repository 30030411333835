import styled from 'styled-components';

import { colors, depth, radii, spacing } from '@hultafors/wibe/helpers';
export const StyledQuestionHelp = styled.div`
  > button svg {
    margin-right: 0;
  }

  .HelpText {
    background-color: ${colors.white};
    border-radius: ${radii.light};
    box-shadow: 0 1px 10px rgb(0 0 0 / 10%);
    display: none;
    left: 0;
    padding: ${spacing.s};
    position: absolute;
    top: 50px;
    width: 292px;
    z-index: ${depth.top};

    > div {
      > :nth-child(1) {
        justify-content: flex-end;
        margin-bottom: ${spacing.s};
        margin-right: 0;
        width: 100%;

        button {
          padding: 0;

          svg path {
            fill: ${colors.grey4};
          }
        }
      }

      > :nth-child(2) {
        display: flex;
        justify-content: center;
        margin-bottom: ${spacing.s};
        width: 100%;
      }
    }

    &.Active {
      display: block;
    }
  }
`;
