import { FavoriteItem } from '@hultafors/shared/types';

import { WibeProduct } from '@hultafors/wibe/types';

export function createProductFromFavorite(
  input: FavoriteItem,
): WibeProduct {
  return {
    productId: input.id,
    name: input.name,
    sku: input.id,
    parentCategory: '',
    image: input.image,
    brandSegments: [],
    price: { value: { min: 0, max: 0 }, currency: '' },
  };
}
