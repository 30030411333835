import styled, { keyframes } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

import { IconButton } from '../icon-button/icon-button';

const appear = keyframes`
  from{transform: translate(50px) }
  to{transform: translate(-16px) }
`;
const disappear = keyframes`
  from{transform: translate(-16px) }
  to{transform: translate(50px) }
`;

interface SearchInputStyledProps {
  $full?: boolean;
}

export const SearchInputStyled = styled.div<SearchInputStyledProps>`
  max-width: ${({ $full }) => ($full ? null : '343px')};
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: ${breakpoints.mobileMax}) {
    max-width: 100%;
  }

  form {
    align-items: center;
    border: thin solid ${colors.grey3};
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    &.focus {
      background-color: ${colors.grey1};
      outline: none;
      border-color: ${colors.wibeYellow};
    }

    input {
      border-bottom: none;
      font-size: ${fontSizes.paragraph16};
      line-height: ${lineHeights.paragraph16};
      padding-bottom: 12px;
      padding-left: 8px;
      padding-top: 12px;

      :focus {
        outline: none;
      }
    }

    .IconButton {
      display: flex;
      height: ${spacing.s};
      padding: 0;
      width: ${spacing.s};

      &.close {
        animation: ${disappear} 0.2s forwards;
        min-height: ${spacing.s};
        min-width: ${spacing.s};
        position: relative;
        z-index: 2;

        &.animate {
          animation: ${appear} 0.2s forwards;
        }
      }

      &.search {
        margin-left: ${spacing.s};
        min-height: ${spacing.s};
        min-width: ${spacing.s};
      }
    }

    .ResultText {
      font-size: ${fontSizes.paragraph16};
      font-weight: ${fontWeights.regular};
      margin-bottom: ${spacing.m};
      margin-top: ${spacing.m};
    }

    &.SearchView {
      .ResultText {
        margin-bottom: 0;
      }

      p {
        font-size: ${fontSizes.header4};
        font-weight: ${fontWeights.medium};
        line-height: ${lineHeights.header4};
      }
    }
  }
`;

export const SearchButton = styled(IconButton)`
  margin-inline-end: ${spacing.xs};
  min-height: 44px;
  height: 100%;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClearButton = styled(IconButton)`
  min-height: 44px;
  width: 44px;
  right: 0;
  top: 2px;
`;
