import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { Flex } from '../flex/flex';

export const FooterStyled = styled.div`
  background-color: ${colors.wibeDarkGrey};
  color: ${colors.white};
  margin-top: auto;
  padding: ${spacing.side};

  .markdown-wrapper {
    margin-left: 6px;
    margin-bottom: 6px;

    &.MoreInfoText {
      margin-top: 36px !important;
    }
  }

  .HultaforsBrands {
    .BrandLink {
      padding: 6px;
      display: inline-block;
    }
  }
`;

export const TopWrapper = styled(Flex)`
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
  }
`;

export const Logo = styled.div`
  height: 40px;
  margin-bottom: ${spacing.l};
  margin-right: auto;
  width: auto;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: initial;
  }
`;

export const SubscribeAreaWrapper = styled.div`
  flex-basis: 25%;
  margin-bottom: ${spacing.m};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
  }
`;

export const BottomFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .SoMeRow {
    margin-bottom: ${spacing.m};

    > * + * {
      margin-left: ${spacing.xs};
    }
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row-reverse;
    justify-content: space-between;

    .SoMeRow {
      margin-bottom: 0;
    }
  }
`;

export const SiteLogoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
