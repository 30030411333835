import React, { Fragment, useId } from 'react';

import dynamic from 'next/dynamic';

import {
  ContentAreaField,
  isBigPlug,
  isCategoryGrid,
  isColorPlugs,
  isContactInfoBlock,
  isDividedTitleAndText,
  isGreyInfoBox,
  isHero,
  isOfficesBlock,
  isPagePlugs,
  isSimpleTextAndCta,
  isTitleAndTextBlock,
  isVideo,
  isVisibleSeoBlock,
} from '@hultafors/wibe/types';

const BigPlug = dynamic(() =>
  import('../big-plug/big-plug').then((mod) => mod.BigPlug),
);
const CategoryGrid = dynamic(() =>
  import('../category-grid/category-grid').then((mod) => mod.CategoryGrid),
);
const ContactInfoBlock = dynamic(() =>
  import('../contact-info-block/contact-info-block').then(
    (mod) => mod.ContactInfoBlock,
  ),
);
const ColorPlugs = dynamic(() =>
  import('../color-plugs/color-plugs').then((mod) => mod.ColorPlugs),
);
const DividedTitleAndText = dynamic(() =>
  import('../divided-text-and-title/divided-title-and-text').then(
    (mod) => mod.DividedTitleAndText,
  ),
);
const Hero = dynamic(() => import('../hero/hero').then((mod) => mod.Hero));
const GreyInfoBox = dynamic(() =>
  import('../grey-info-box/grey-info-box').then((mod) => mod.GreyInfoBox),
);
const OfficesBlock = dynamic(() =>
  import('../offices-block/offices-block').then((mod) => mod.OfficesBlock),
);
const PagePlugs = dynamic(() =>
  import('../page-plugs/page-plugs').then((mod) => mod.PagePlugs),
);
const SimpleTextAndCta = dynamic(() =>
  import('../simple-text-and-cta/simple-text-and-cta').then(
    (module) => module.SimpleTextAndCta,
  ),
);
const TitleAndTextBlock = dynamic(() =>
  import('../title-and-text-block/title-and-text-block').then(
    (mod) => mod.TitleAndTextBlock,
  ),
);

const VisibleSeoBlock = dynamic(() =>
  import('../visible-seo-block/visible-seo-block').then(
    (mod) => mod.VisibleSeoBlock,
  ),
);

const Video = dynamic(() => import('../video/video').then((mod) => mod.Video));

export interface ContentAreaProps {
  content: ContentAreaField[];
}

export const ContentArea: React.FC<ContentAreaProps> = ({ content }) => {
  const uid = useId();
  const contentMapper = (item: ContentAreaField, index: number) => {
    const key = `ContentArea-${uid}-${item.__typename}-${index}`;

    if (isBigPlug(item)) {
      return <BigPlug {...item} key={key} />;
    }
    if (isCategoryGrid(item)) {
      return <CategoryGrid {...item} key={key} />;
    }
    if (isColorPlugs(item)) {
      return <ColorPlugs {...item} key={key} />;
    }
    if (isContactInfoBlock(item)) {
      return <ContactInfoBlock {...item} key={key} />;
    }
    if (isDividedTitleAndText(item)) {
      return <DividedTitleAndText {...item} key={key} />;
    }
    if (isGreyInfoBox(item)) {
      return <GreyInfoBox {...item} key={key} />;
    }
    if (isHero(item)) {
      return <Hero {...item} key={key} priority={index === 0} />;
    }
    if (isOfficesBlock(item)) {
      return <OfficesBlock {...item} key={key} />;
    }
    if (isPagePlugs(item)) {
      return <PagePlugs {...item} key={key} />;
    }
    if (isSimpleTextAndCta(item)) {
      return <SimpleTextAndCta {...item} key={key} />;
    }
    if (isTitleAndTextBlock(item)) {
      return <TitleAndTextBlock {...item} key={key} />;
    }
    if (isVisibleSeoBlock(item)) {
      return <VisibleSeoBlock {...item} key={key} />;
    }

    if (isVideo(item)) {
      return (
        <Video key={key} src={item.video} thumbnail={item.videoThumbnail} />
      );
    }

    return null;
  };
  return <Fragment>{content.map(contentMapper)}</Fragment>;
};
