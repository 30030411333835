import Link from 'next/link';

import { spacing } from '@hultafors/wibe/helpers';
import {
  AddressFragment,
  ContactInfoBlockFragment,
} from '@hultafors/wibe/types';

import { HTag } from '../h-tag/h-tag';
import { Icon } from '../icon/icon';
import { Paragraph } from '../paragraph/paragraph';

import { ContactInformationStyled } from './contact-information.styled';

type ContactBlockProps = Omit<ContactInfoBlockFragment, 'jobLink'>;

export const ContactInformation: React.FC<
  ContactInformationProps | ContactBlockProps
> = ({
  addressInformation,
  addressLabel,
  phoneLabel,
  emailLabel,
  localContactLabel,
  companyName,
  directionsLabel,
}) => {
  const renderAdressBlock = (
    title?: string | null | undefined,
    name?: string | null | undefined,
    address?: string | null | undefined,
    city?: string | null | undefined,
    country?: string | null | undefined,
    directions?: string | null | undefined,
  ) => {
    if (!title && !address) return null;
    return renderBlock(
      title,
      [name, address, city, country].filter(Boolean),
      'Address',
      directions,
    );
  };

  const renderPhoneNumbersBlock = (
    title?: string | null | undefined,
    phone?: string | null | undefined,
  ) => {
    if (!title || !phone) {
      return null;
    }

    const tags = [
      <a key="phoneKey" href={`tel:${phone.replace(/ /g, '')}`}>
        <Icon svg="phone" />
        {phone}
      </a>,
    ];
    return renderBlock(title, tags, 'Phone');
  };

  const renderEmailBlock = (
    title?: string | null | undefined,
    email?: string | null | undefined,
  ) => {
    if (!title && !email) return;
    const aTag = email
      ? (
        <a href={`mailto:${email}`}>
          <Icon svg="mail" />
          {email}
        </a>
        )
      : (
          ''
        );
    return renderBlock(title, [aTag], 'Email');
  };

  const renderBlock = (
    title?: string | null | undefined,
    list?: React.ReactNode[],
    key?: string,
    directions?: string | null | undefined,
  ) => {
    return (
      <div className="TextBlock">
        {title && (
          <Paragraph className="Title" styleType="paragraph16Medium">
            {title}
          </Paragraph>
        )}
        {list?.map((item, i) => {
          return (
            <Paragraph styleType="paragraph16" key={`${key}-${i}`}>
              {item}
            </Paragraph>
          );
        })}
        {directions && (
          <Paragraph
            className="Title"
            styleType="paragraph16"
            style={{ marginTop: spacing.s, marginBottom: spacing.s }}
          >
            <Link href={directions}>
              <Icon svg="place-tag" />
              {directionsLabel}
            </Link>
          </Paragraph>
        )}
      </div>
    );
  };

  if (!addressInformation || !localContactLabel) {
    return null;
  }

  return (
    <ContactInformationStyled>
      {addressInformation?.addressHeader && (
        <HTag type="h2" styleType="header3" style={{ marginBottom: spacing.m }}>
          {addressInformation?.addressHeader}
        </HTag>
      )}
      {renderAdressBlock(
        addressLabel,
        companyName,
        addressInformation?.streetAddress,
        addressInformation?.city,
        addressInformation?.country,
        addressInformation?.addressDirectionsLink,
      )}

      {addressInformation?.phoneNumber
      && renderPhoneNumbersBlock(phoneLabel, addressInformation?.phoneNumber)}
      {addressInformation?.email
      && renderEmailBlock(emailLabel, addressInformation.email)}

      {renderBlock(
        localContactLabel,
        [
          null,
          addressInformation?.visitingAddress,
          addressInformation?.visitingCity,
          addressInformation?.visitingCountry,
        ],
        'Local',
        addressInformation?.visitingAddressDirectionsLink,
      )}
      {addressInformation?.description && (
        <Paragraph className="Disclaimer">
          {addressInformation?.description}
        </Paragraph>
      )}
    </ContactInformationStyled>
  );
};

interface ContactInformationProps {
  addressHeader?: string;
  addressLabel?: string;
  phoneLabel?: string;
  emailLabel?: string;
  localContactLabel?: string;
  companyName?: string;
  phoneNumber?: string;
  localContactDirectionsLink?: string;
  directionsLabel?: string;
  addressInformation?: AddressFragment;
}
