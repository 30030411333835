import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

import { HTag } from '../h-tag/h-tag';

export const ContactStoresStyled = styled.div`
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: ${spacing.l};
  width: calc(100vw - 2 * ${spacing.s});

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    width: 60vw;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 47vw;
    max-width: 688px;
  }
`;

export const Label = styled(HTag).attrs({ type: 'h3', styleType: 'header4' })``;
