import dynamic from 'next/dynamic';

import { spacing } from '@hultafors/wibe/helpers';
import { useFavorites, useGlobal } from '@hultafors/wibe/hooks';
import { WibeProduct } from '@hultafors/wibe/types';

import StarYellowSvg from '../../svg/star-yellow.svg';
import { Button } from '../button/button';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { HTag } from '../h-tag/h-tag';
import { Icon } from '../icon/icon';
import { Product } from '../product/product';

import { ClearLabel, FavoritesStyled, HeaderInner } from './favorites.styled';

const SlideOutHeader = dynamic(
  () =>
    import('@hultafors/shared/components').then((mod) => mod.SlideOutHeader),
  { ssr: false },
);

const SlideOut = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.SlideOut),
  { ssr: false },
);

const ClearButton = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.ClearButton),
);

interface FavoritesProps {
  isOpen: boolean;
  toggleMenu(): void;
  header: string;
  clearText: string;
  badgeText: string;
  rrpLabel?: string;
  rrpIncludingVatLabel?: string;
  favoritesEmpty?: string;
  isSmall?: boolean;
}

export const Favorites: React.FC<FavoritesProps> = ({
  toggleMenu,
  isOpen,
  header,
  clearText,
  badgeText,
  rrpLabel,
  rrpIncludingVatLabel,
  favoritesEmpty,
}) => {
  const { global } = useGlobal();
  const { favorites, clearFavorites } = useFavorites();

  function itemMapper(item: WibeProduct) {
    return (
      <GridChild
        key={`Favorite-${item.productId}`}
        columnSpan={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 1 }]}
      >
        <Product
          product={item}
          isFavorite
          inFavorites={true}
          badgeText={badgeText}
          rrpLabel={rrpLabel}
          rrpIncludingVatLabel={rrpIncludingVatLabel}
          noHover
          toggleMenu={toggleMenu}
        />
      </GridChild>
    );
  }

  const slideOutHeader = (
    <SlideOutHeader onClick={toggleMenu} closeLabel={global.close ?? ''}>
      <HeaderInner>
        <StarYellowSvg
          aria-hidden={true}
          focusable={false}
          width={16}
          height={16}
        />
        {header}
        <ClearButton
          onClick={clearFavorites}
          disabled={!favorites?.length}
          title={clearText}
        />
      </HeaderInner>
    </SlideOutHeader>
  );

  return (
    <SlideOut isOpen={isOpen} toggle={toggleMenu} header={slideOutHeader}>
      <FavoritesStyled data-test="Favorites">
        {favorites?.length
          ? (
            <>
              <Button
                onClick={clearFavorites}
                variant="transparent"
                style={{
                  marginBottom: spacing.s,
                  padding: spacing.xs,
                  paddingLeft: 0,
                }}
              >
                <Icon svg="trash" style={{ marginLeft: 0 }} />
                <ClearLabel>{clearText}</ClearLabel>
              </Button>
              <Grid columns={1}>{favorites.map(itemMapper)}</Grid>
            </>
            )
          : (
            <HTag center type="h3" styleType="header3">
              {favoritesEmpty}
            </HTag>
            )}
      </FavoritesStyled>
    </SlideOut>
  );
};
