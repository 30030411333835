import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontWeights } from '@hultafors/wibe/helpers';

import { H1 } from '../h1/h1';

interface StyledCampaignHeroProps {
  $imageMobile?: boolean;
}
export const StyledCampaignHero = styled.div<StyledCampaignHeroProps>`
  position: relative; /* anchor for .hero-text-wrapper */
  aspect-ratio: 3 / 4;

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 1 / 1;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 21 / 9;
  }
`;

export const HeroImageWrapper = styled.div<{ $hasImage?: boolean }>`
  color: ${({ $hasImage }) => ($hasImage ? colors.white : colors.black)};
  height: auto;
  width: 100%;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    background-color: ${colors.white};
    color: ${colors.white};
    min-height: auto;
  }
`;

export const HeroTextWrapper = styled.div`
  position: absolute;
  inset: 0;
  align-items: left;
  display: flex;
  flex-direction: column;
  padding-right: 20vw;
  padding-top: 16vw;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    justify-content: center;
    padding-top: 2vw;
    padding-right: 38vw;
  }
`;

export const Title = styled(H1).attrs({ bold: true })`
  color: ${colors.wibeGreen};
  font-size: 13vw; /* TODO */
  line-height: 16vw;
  font-style: normal;
  font-weight: ${fontWeights.extraBold};
  margin: 0;
  margin-bottom: 2vw;
  display: flex;
  justify-content: center;
  text-align: center;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: 9vw;
    line-height: 11vw;
    margin-bottom: 1vw;
  }

  > span {
    background-color: ${colors.wibeYellowAlpha};
    padding: 0 2vw;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: 0 1vw;
    }
  }
`;

export const Description = styled.p`
  color: ${colors.wibeYellow};
  font-size: 10.5vw;
  line-height: 13.5vw;
  font-style: normal;
  font-weight: ${fontWeights.extraBold};
  white-space: pre;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: 7vw;
    line-height: 9vw;
  }

  > span {
    display: block;
    background-color: ${colors.wibeGreenAlpha};
    padding: 0 2.5vw;
    margin-bottom: 2vw;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-bottom: 1vw;
    }
  }
`;
