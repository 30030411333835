export const letterSpacings = {
  mega2: '-0.01em',
  mega1: '-0.01em',
  heroLabel: '0.125em',
  spiritLevelAlumini: '0em',
  paragraph12: '0em',
  header4: '0em',
  paragraph14: '0em',
  paragraph16: '0em',
  header1: '-0.01em',
  header2: '-0.01em',
  header3: '0.005em',
} as const;

export type LetterSpacing = keyof typeof letterSpacings;
