import { spacing } from '@hultafors/wibe/helpers';
import { Maybe } from '@hultafors/wibe/types';

import { Button } from '../button/button';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { HTag } from '../h-tag/h-tag';
import { TextButton } from '../text-button/text-button';

import { ButtonWrapper, Container, StyledMarkdown } from './text-block.styled';

interface TextBlockProps {
  body?: Maybe<string>;
  ctaText?: Maybe<string>;
  ctaLink?: Maybe<string>;
  header?: Maybe<string>;
  ctaIsLink?: boolean;
  isBigHeader?: boolean;
  className?: string;
}

export const TextBlock: React.FC<TextBlockProps> = ({
  className,
  header,
  isBigHeader,
  body,
  ctaText,
  ctaLink,
  ctaIsLink,
}) => {
  return (
    <Container className={className}>
      <Grid columnGap={0}>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          {header != '' && (
            <HTag
              type="h2"
              styleType={isBigHeader ? 'header3' : 'header4'}
              style={{ marginBottom: spacing.s }}
            >
              {header}
            </HTag>
          )}
          <StyledMarkdown>{body ?? ''}</StyledMarkdown>

          {ctaText && ctaLink && (
            <ButtonWrapper>
              {ctaIsLink
                ? (
                  <TextButton to={ctaLink} arrow>
                    {ctaText}
                  </TextButton>
                  )
                : (
                  <Button to={ctaLink} variant="rounded">
                    {ctaText}
                  </Button>
                  )}
            </ButtonWrapper>
          )}
        </GridChild>
      </Grid>
    </Container>
  );
};
