import React from 'react';

import Link from 'next/link';

import { BreadcrumbsStyled, Divider, Inner } from './breadcrumbs.styled';

interface BreadcrumbsProps {
  links?: any[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ links = [] }) => {
  return (
    (links && links.length !== 0 && (
      <BreadcrumbsStyled>
        <Inner>
          {links.map((link, index, all) => {
            return (
              <React.Fragment key={`breadcrumb-${index}`}>
                <Link href={link.url}>{link.name}</Link>
                {index !== all.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
        </Inner>
      </BreadcrumbsStyled>
    ))
    || null
  );
};
