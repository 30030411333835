import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

import { Section } from '../section/section';

export const StyledCategorySection = styled(Section).attrs({
  paddingBlockEnd: 'none',
})`
  margin-bottom: ${spacing.l};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-bottom: ${spacing.xl};
  }
`;
