import styled from 'styled-components';

import { buttonReset } from '@hultafors/shared/helpers';

interface NavFavoritesStyledProps {
  $inTopMenu?: boolean;
  $hasFavorites?: boolean;
}

export const NavFavoritesStyled = styled.button<NavFavoritesStyledProps>`
  ${buttonReset};
  cursor: pointer;
  position: relative;
  height: 24px;
  width: 24px;
  line-height: 0;

  .Badge {
    right: -8px;
    top: -7px;
  }
`;
