import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

export const StyledQuote = styled.div`
  margin: ${spacing.l} ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.xl} 0;
  }

  .AuthorWrapper {
    display: flex;
    padding-top: ${spacing.xs};
  }

  .Author {
    padding-right: ${spacing.xs};
  }

  .Profession {
    color: ${colors.systemBlack};
  }

  .text-wrapper {
    border-left: 2px solid ${colors.wibeYellow};
    padding-left: 13px;
  }
`;
