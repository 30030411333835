import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { HTag } from '../h-tag/h-tag';

export const Container = styled.div`
  margin: ${spacing.l} ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.xl} 0;
  }
`;

export const StyledMarkdown = styled(Markdown).attrs({
  options: {
    forceBlock: true,
    overrides: {
      h1: {
        component: HTag,
        props: {
          type: 'h4',
          styleType: 'header4',
          style: { marginBottom: spacing.s },
        },
      },
    },
  },
})`
  white-space: pre-line;

  p:not(:last-child) {
    margin-bottom: ${spacing.m};
  }

  a {
    color: ${colors.black};
    text-decoration: none;
    text-decoration: underline;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.m};
`;
