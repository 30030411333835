import styled from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

export const OnlineStoreListStyled = styled.div`
  > * {
    border-bottom: 1px solid ${colors.grey2};
    color: ${colors.systemBlack};
    display: flex;
    font-size: ${fontSizes.paragraph16};
    font-weight: ${fontWeights.medium};
    line-height: ${lineHeights.paragraph16};
    padding: ${spacing.m} 0 ${spacing.m} ${spacing.l};
    position: relative;
    text-decoration: none;
  }

  > *:last-child {
    border-bottom: none;
  }

  svg {
    left: 0;
    position: absolute;

    path {
      stroke: ${colors.systemBlack};
    }
  }

  a:hover {
    color: ${colors.systemBlack};

    svg {
      path {
        stroke: ${colors.systemBlack};
      }
    }
  }
`;
