import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

export const StyledDynamicContent = styled.div`
  margin-bottom: ${spacing.l};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-bottom: ${spacing.xl};
  }

  > div:last-of-type {
    padding-bottom: 0;
  }

  &:empty {
    display: none;
  }
`;
